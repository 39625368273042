import React from "react";
import ViewReverse from "../../../components/ViewReverse";
import ViewItems from "../../../components/ViewItems";
import Text from "../../../components/Text";
import { fontSizes } from "../../../constants/constants";
import icons from "../../../constants/icons";
import { ReactSVG } from "react-svg";
import { ConfigProvider, Table } from "antd";
import { transactionColumns, transactionData } from "../../../config/config";
import dynamicfunction from "../../../utils/dynamicfunction";
import { useSelector } from "react-redux";
import { getContentKeyAccToLang } from "../../../utils/utils";

const OrderDetails = () => {
  const { tradingContent } = useSelector(state => state.content);
  const { TextWeight, isArabic } = dynamicfunction();

  const getContentKey = (key) => {
    return getContentKeyAccToLang(
      tradingContent?.web_trading_order?.data?.attributes,
      key,
      isArabic
    );
  };

  return (
    <div className="w-full mt-2">
      <ViewReverse className={"w-full gap-4"}>
        <ViewItems className={"w-1/2 gap-4"}>
          <ViewReverse
            className={
              "w-full justify-between items-center border border-grey bg-grey bg-opacity-30 px-4 py-3 rounded"
            }
          >
            <ViewReverse className={"gap-4"}>
              <ViewItems className={"gap-2"}>
                <Text text="1020" color="text-dark-blue" size={fontSizes.h14} />
                <Text
                  text="Al-Jazira Bank"
                  color="text-light-blue"
                  size={fontSizes.h14}
                />
              </ViewItems>
              <ViewItems className={"gap-2"}>
                <Text
                  text={getContentKey("queued")}
                  color="text-dark-blue"
                  size={fontSizes.h14}
                />
                <Text
                  text="Limit order"
                  color="text-light-blue"
                  size={fontSizes.h14}
                />
              </ViewItems>
            </ViewReverse>

            <ViewReverse className={"items-center gap-4"}>
              <ReactSVG src={icons.roundArrow} />
              <ReactSVG src={icons.modalCross} />
              <ReactSVG src={icons.edit} />
            </ViewReverse>
          </ViewReverse>

          <ViewItems className={"w-full border border-grey rounded p-4"}>
            <Text
              text={getContentKey("cancelled_orders")}
              size={fontSizes.h14}
              color="text-primary-light"
            />

            <ViewReverse className={"w-full gap-3 pb-5"}>
              <ViewItems className={"w-1/2"}>
                <ViewReverse
                  className={
                    "w-full justify-between border-b border-b-grey py-2"
                  }
                >
                  <Text text={getContentKey("remaining_quantity")} size={fontSizes.h14} />
                  <Text text="12" size={fontSizes.h14} />
                </ViewReverse>
                <ViewReverse
                  className={
                    "w-full justify-between border-b border-b-grey py-2"
                  }
                >
                  <Text text={getContentKey("avg_ex_price")} size={fontSizes.h14} />
                  <Text text="0.0000" size={fontSizes.h14} />
                </ViewReverse>
                <ViewReverse
                  className={
                    "w-full justify-between border-b border-b-grey py-2"
                  }
                >
                  <Text text={getContentKey("executed_fees")} size={fontSizes.h14} />
                  <Text text="0.00" size={fontSizes.h14} />
                </ViewReverse>
                <ViewReverse
                  className={
                    "w-full justify-between border-b border-b-grey py-2"
                  }
                >
                  <Text text={getContentKey("las_executed_time")} size={fontSizes.h14} />
                </ViewReverse>
              </ViewItems>
              <ViewItems className={"w-1/2"}>
                <ViewReverse
                  className={
                    "w-full justify-between border-b border-b-grey py-2"
                  }
                >
                  <Text text={getContentKey("executed_qty")} size={fontSizes.h14} />
                  <Text text="0" size={fontSizes.h14} />
                </ViewReverse>
                <ViewReverse
                  className={
                    "w-full justify-between border-b border-b-grey py-2"
                  }
                >
                  <Text text={getContentKey("executed_amount")} size={fontSizes.h14} />
                  <Text text="0.00" size={fontSizes.h14} />
                </ViewReverse>
                <ViewReverse
                  className={
                    "w-full justify-between border-b border-b-grey py-2"
                  }
                >
                  <Text text={getContentKey("total_executed_amount")} size={fontSizes.h14} />
                  <Text text="0.00" size={fontSizes.h14} />
                </ViewReverse>
                <ViewReverse
                  className={
                    "w-full justify-between border-b border-b-grey py-2"
                  }
                >
                  <Text text={getContentKey("market_respond")} size={fontSizes.h14} />
                </ViewReverse>
              </ViewItems>
            </ViewReverse>
          </ViewItems>
        </ViewItems>

        <ViewItems className={"w-1/2 border border-grey rounded p-4"}>
          <Text
            text={getContentKey("cancelled_orders")}
            size={fontSizes.h14}
            color="text-primary-light"
            className={"mb-2"}
          />

          <ViewReverse className={"w-full gap-3"}>
            <ViewItems className={"w-1/2"}>
              <ViewReverse
                className={"w-full justify-between border-b border-b-grey py-2"}
              >
                <Text text={getContentKey("portfolio")} size={fontSizes.h14} />
                <Text text="1-TADAWUL1" size={fontSizes.h14} />
              </ViewReverse>
              <ViewReverse
                className={"w-full justify-between border-b border-b-grey py-2"}
              >
                <Text text={getContentKey("fill_term")} size={fontSizes.h14} />
                <Text text="Market default" size={fontSizes.h14} />
              </ViewReverse>
              <ViewReverse
                className={"w-full justify-between border-b border-b-grey py-2"}
              >
                <Text text={getContentKey("order_date")} size={fontSizes.h14} />
                <Text text="14/09/2020" size={fontSizes.h14} />
              </ViewReverse>
              <ViewReverse
                className={"w-full justify-between border-b border-b-grey py-2"}
              >
                <Text text={getContentKey("disclosed_volume")} size={fontSizes.h14} />
                <Text text="0" size={fontSizes.h14} />
              </ViewReverse>
              <ViewReverse
                className={"w-full justify-between border-b border-b-grey py-2"}
              >
                <Text text={getContentKey("fees_amount")} size={fontSizes.h14} />
                <Text text="0.20" size={fontSizes.h14} />
              </ViewReverse>
              <ViewReverse
                className={"w-full justify-between border-b border-b-grey py-2"}
              >
                <Text text={getContentKey("market_order_no")} size={fontSizes.h14} />
              </ViewReverse>
            </ViewItems>
            <ViewItems className={"w-1/2"}>
              <ViewReverse
                className={"w-full justify-between border-b border-b-grey py-2"}
              >
                <Text text={getContentKey("cash_account")} size={fontSizes.h14} />
                <Text text="78110000167" size={fontSizes.h14} />
              </ViewReverse>
              <ViewReverse
                className={"w-full justify-between border-b border-b-grey py-2"}
              >
                <Text text={getContentKey("min_qty")} size={fontSizes.h14} />
                <Text text="0" size={fontSizes.h14} />
              </ViewReverse>
              <ViewReverse
                className={"w-full justify-between border-b border-b-grey py-2"}
              >
                <Text text={getContentKey("expiry_date")} size={fontSizes.h14} />
                <Text text="14/09/2020" size={fontSizes.h14} />
              </ViewReverse>
              <ViewReverse
                className={"w-full justify-between border-b border-b-grey py-2"}
              >
                <Text text={getContentKey("trade_amount")} size={fontSizes.h14} />
                <Text text="198.00" size={fontSizes.h14} />
              </ViewReverse>
              <ViewReverse
                className={"w-full justify-between border-b border-b-grey py-2"}
              >
                <Text text={getContentKey("order_amount")} size={fontSizes.h14} />
                <Text text="198.21" size={fontSizes.h14} />
              </ViewReverse>
            </ViewItems>
          </ViewReverse>
        </ViewItems>
      </ViewReverse>

      <ViewItems className={"w-full border border-grey rounded p-4 mt-4"}>
        <Text
          text={getContentKey("transaction")}
          size={fontSizes.h14}
          color="text-primary-light"
          className={"mb-2"}
        />

        <ConfigProvider
          theme={{
            components: {
              Table: {
                headerBg: "#E0E0E0",
                headerSplitColor: "transparent",
                borderRadius: 0,
                padding: 15,
              },
            },
          }}
        >
          <Table
            columns={isArabic ? transactionColumns(TextWeight, getContentKey)?.reverse() : transactionColumns(TextWeight, getContentKey)}
            dataSource={transactionData}
            pagination={false}
            className="rounded-tr-[10px] rounded-tl-[10px] border-t-grey border overflow-hidden"
            scroll={{
              x: "max-content",
              y: 250,
            }}
            locale={{ emptyText: "No data available in table" }}
            onRow={(i) => ({
              // onClick: (e) => {
              // },
            })}
          />
        </ConfigProvider>
      </ViewItems>
    </div>
  );
};

export default OrderDetails;
