import React, { useState } from "react";
import ViewItems from "../../../components/ViewItems";
import ViewReverse from "../../../components/ViewReverse";
import Text from "../../../components/Text";
import dynamicfunction from "../../../utils/dynamicfunction";
import { fontSizes } from "../../../constants/constants";
import Button from "../../../components/Button";
import { ReactSVG } from "react-svg";
import icons from "../../../constants/icons";
import { ConfigProvider, Table, Tabs } from "antd";
import {
  orderBasketColumns,
  orderBasketData,
  portfolioColumns,
  priceSpectrum,
  watchListColumns,
} from "../../../config/config";
import ProgressBar from "../../../components/ProgressBar";
import { useSelector } from "react-redux";
import Loader from "../../../components/Loader";
import BuyModal from "../../../components/BuyModal";
import { rowReverseStylesm } from "../../../utils/styles";
import { getContentKeyAccToLang } from "../../../utils/utils";

const TradingOverview = () => {
  const { tradingContent } = useSelector((state) => state.content);
  const {
    marketSummary,
    watchList,
    portfolioSummary,
    portfolioPostion,
    orderBasketList,
  } = useSelector((state) => state.trading);

  const { TextWeight, isArabic } = dynamicfunction();

  const [isDetailOpen, setIsDetailOpen] = useState({
    watchList: true,
    priceSpectrum: true,
    protfolioSummary: true,
    portfolioPosition: true,
    orderBasket: true,
    transactionTicket: true,
  });

  const [isBuyModal, setIsBuyModal] = useState(false);

  const getContentKey = (key) => {
    return getContentKeyAccToLang(
      tradingContent?.web_trading_overview?.data?.attributes,
      key,
      isArabic
    );
  };

  const toogleFullScreen = (key) =>
    setIsDetailOpen((prev) => ({
      ...prev,
      [key]: !prev[key],
    }));

  return (
    <div>
      <ViewReverse className={"justify-between"}>
        <Text
          text={getContentKey("overview")}
          weight={TextWeight.headingBold}
          size={fontSizes.h24}
          color="text-dark-blue"
        />

        <ViewReverse className={"gap-3 relative"}>
          <ViewReverse className={"items-center gap-2 me-3"}>
            <div className="h-4 w-4 rounded-full bg-green-500" />
            <Text
              text={getContentKey("connected")}
              className={"uppercase opacity-50"}
              size={fontSizes.h10}
              weight={TextWeight.bold}
              color="text-dark-grey"
            />
          </ViewReverse>
          <Button
            text={getContentKey("buy")}
            bgColor="bg-light-blue"
            weight={TextWeight.extraBold}
            className={"px-5"}
            onClick={() => setIsBuyModal(!isBuyModal)}
          />
          <BuyModal
            isOpen={isBuyModal}
            onClose={() => setIsBuyModal(!isBuyModal)}
          />
          <Button
            text={getContentKey("sell")}
            bgColor="bg-dark-blue"
            weight={TextWeight.extraBold}
            className={"px-5"}
          />
        </ViewReverse>
      </ViewReverse>

      {marketSummary ? (
        <ViewItems
          className={"border border-grey mt-6 rounded px-5 py-3 pb-10"}
        >
          <ViewReverse className={"w-full items-center justify-between mb-2"}>
            <ViewReverse className={"gap-2"}>
              <Text
                text={getContentKey("market_summary")}
                color="text-primary-light"
                size={fontSizes.h14}
              />
              <Text
                text={getContentKey("close")}
                color="text-red-500"
                weight={TextWeight.bold}
                size={fontSizes.h14}
              />
            </ViewReverse>
            <ViewReverse className={"items-center gap-2"}>
              <div className="h-3 w-3 bg-grey rounded-full" />
              <ReactSVG src={icons.filter} />
            </ViewReverse>
          </ViewReverse>
          <ViewReverse className={"gap-12 flex-wrap"}>
            <ViewItems>
              <ViewReverse className={"items-center gap-2"}>
                <Text
                  text={marketSummary?.totalBalance}
                  size={fontSizes.h22}
                  weight={TextWeight.light}
                />
                <ReactSVG
                  src={
                    marketSummary?.totalMarketProfitOrLossPercentage > 0
                      ? icons.up
                      : icons.down
                  }
                />
              </ViewReverse>
              <Text
                text={`${marketSummary?.totalMarketProfitOrLoss} (${marketSummary?.totalMarketProfitOrLossPercentage}%)`}
                size={fontSizes.h14}
                color={
                  marketSummary?.totalMarketProfitOrLossPercentage > 0
                    ? "text-green-500"
                    : "text-red-500"
                }
                weight={TextWeight.bold}
              />
            </ViewItems>
            <ViewItems className={"gap-1"}>
              <Text
                text={getContentKey("turnover")}
                size={fontSizes.h14}
                className={"opacity-40"}
              />
              <Text
                text={marketSummary?.turnover?.toLocaleString()}
                size={fontSizes.h14}
              />
            </ViewItems>
            <ViewItems className={"gap-1"}>
              <Text
                text={getContentKey("volumn")}
                size={fontSizes.h14}
                className={"opacity-40"}
              />
              <Text
                text={marketSummary?.tVolume?.toLocaleString()}
                size={fontSizes.h14}
              />
            </ViewItems>
            <ViewItems className={"gap-1"}>
              <Text
                text={getContentKey("trades")}
                size={fontSizes.h14}
                className={"opacity-40"}
              />
              <Text
                text={marketSummary?.trades?.toLocaleString()}
                size={fontSizes.h14}
              />
            </ViewItems>
            <ViewItems className={"gap-1"}>
              <Text
                text={getContentKey("traded")}
                size={fontSizes.h14}
                className={"opacity-40"}
              />
              <Text
                text={marketSummary?.traded?.toLocaleString()}
                size={fontSizes.h14}
              />
            </ViewItems>
            <ViewItems>
              <ViewReverse className={"gap-2"}>
                <ReactSVG src={icons.up} className="mt-2" />
                <ViewItems className={"gap-1"}>
                  <Text
                    text={getContentKey("up")}
                    size={fontSizes.h14}
                    className={"opacity-40"}
                  />
                  <Text
                    text={marketSummary?.up?.toLocaleString()}
                    size={fontSizes.h14}
                  />
                </ViewItems>
              </ViewReverse>
            </ViewItems>
            <ViewItems>
              <ViewReverse className={"gap-2"}>
                <ReactSVG src={icons.down} className="mt-2" />
                <ViewItems className={"gap-1"}>
                  <Text
                    text={getContentKey("down")}
                    size={fontSizes.h14}
                    className={"opacity-40"}
                  />
                  <Text
                    text={marketSummary?.down?.toLocaleString()}
                    size={fontSizes.h14}
                  />
                </ViewItems>
              </ViewReverse>
            </ViewItems>
          </ViewReverse>
        </ViewItems>
      ) : (
        <Loader />
      )}

      <ViewItems className={"border border-grey mt-6 rounded px-5 py-3 gap-3"}>
        <ViewReverse className={"w-full items-center justify-between"}>
          <Text
            text={getContentKey("watch_list")}
            color="text-primary-light"
            size={fontSizes.h14}
          />
          <ViewReverse className={"gap-2 items-center"}>
            <div className="w-4 h-4 bg-red-500 rounded-full" />
            <ReactSVG src={icons.plus} className="opacity-20" />
            <ReactSVG src={icons.settings} className="opacity-40" />
            <ReactSVG
              className="cursor-pointer"
              src={
                isDetailOpen.watchList
                  ? icons.smallScreen
                  : icons.enterFullScreen
              }
              onClick={() => toogleFullScreen("watchList")}
            />
          </ViewReverse>
        </ViewReverse>

        {isDetailOpen.watchList ? (
          <ConfigProvider
            theme={{
              components: {
                Table: {
                  headerBg: "#E0E0E0",
                  headerSplitColor: "transparent",
                  borderRadius: 0,
                  padding: 10,
                },
              },
            }}
          >
            <Table
              columns={
                isArabic
                  ? watchListColumns(TextWeight, getContentKey).reverse()
                  : watchListColumns(TextWeight, getContentKey)
              }
              dataSource={watchList}
              pagination={false}
              className="rounded-tr-[10px] rounded-tl-[10px] border-t-grey border overflow-hidden"
              scroll={{
                x: "max-content",
                y: 250,
              }}
              onRow={(i) => ({
                onClick: (e) => {},
              })}
            />
          </ConfigProvider>
        ) : null}
      </ViewItems>

      <ViewReverse
        className={`w-full gap-5 flex-col ${rowReverseStylesm(isArabic)}`}
      >
        <ViewItems
          className={
            "w-full sm:w-1/2 border border-grey mt-6 rounded px-5 py-3"
          }
        >
          <ViewReverse className={"w-full items-center justify-between mb-3"}>
            <Text
              text={getContentKey("price_spectrum")}
              color="text-primary-light"
              size={fontSizes.h14}
            />
            <ViewReverse className={"gap-2 items-center"}>
              <div className="w-4 h-4 bg-orange-500 rounded-full" />
              <ReactSVG src={icons.filter} />
              <ReactSVG
                className="cursor-pointer"
                src={
                  isDetailOpen.priceSpectrum
                    ? icons.smallScreen
                    : icons.enterFullScreen
                }
                onClick={() => toogleFullScreen("priceSpectrum")}
              />
            </ViewReverse>
          </ViewReverse>
          {isDetailOpen.priceSpectrum ? (
            <>
              <ViewReverse className={"w-full bg-grey py-1 rounded mb-3"}>
                <Text
                  text={getContentKey("bids")}
                  size={fontSizes.h14}
                  weight={TextWeight.bold}
                  className={"w-2/5 text-center"}
                />
                <Text
                  text={getContentKey("price")}
                  size={fontSizes.h14}
                  weight={TextWeight.bold}
                  className={"w-1/5 text-center"}
                />
                <Text
                  text={getContentKey("offers")}
                  size={fontSizes.h14}
                  weight={TextWeight.bold}
                  className={"w-2/5 text-center"}
                />
              </ViewReverse>
              <div className="w-full">
                {priceSpectrum.map((item, index) => (
                  <ViewReverse className="border-b border-b-grey">
                    <div className="w-2/5">
                      <ProgressBar
                        text={item.bid}
                        percentage={item.bidPercentage}
                      />
                    </div>
                    <div className="w-1/5">
                      <Text
                        text={item.price}
                        size={fontSizes.h14}
                        className={"text-center"}
                      />
                    </div>
                    <div className="w-2/5">
                      <ProgressBar
                        text={item.offer}
                        isred
                        isTextRight
                        percentage={item.offerPercentage}
                      />
                    </div>
                  </ViewReverse>
                ))}
              </div>
              <ViewReverse className={"w-full bg-grey py-1 rounded mt-3"}>
                <ViewReverse className={"w-2/5 justify-center gap-1"}>
                  <Text
                    text={getContentKey("t_bids")}
                    color="text-light-blue"
                    size={fontSizes.h14}
                  />
                  <Text
                    text="52,636 (52)"
                    color="text-green-500"
                    size={fontSizes.h14}
                  />
                </ViewReverse>
                <div className="w-1/5" />
                <ViewReverse className={"w-2/5 justify-center gap-1"}>
                  <Text
                    text={getContentKey("t_offers")}
                    color="text-light-blue"
                    size={fontSizes.h14}
                  />
                  <Text
                    text="136,692 (37)"
                    color="text-red-500"
                    size={fontSizes.h14}
                  />
                </ViewReverse>
              </ViewReverse>
            </>
          ) : null}
        </ViewItems>
        <ViewItems
          className={
            "w-full sm:w-1/2 border border-grey mt-6 rounded px-5 py-3"
          }
        >
          <ViewReverse className={"w-full items-center justify-between mb-3"}>
            <Text
              text={getContentKey("portfolio_summary")}
              color="text-primary-light"
              size={fontSizes.h14}
            />
            <ViewReverse className={"gap-2 items-center"}>
              <div className="w-4 h-4 bg-grey rounded-full" />
              <ReactSVG src={icons.filter} />
              <ReactSVG src={icons.roundArrow} className="opacity-25" />
              <ReactSVG
                className="cursor-pointer"
                src={
                  isDetailOpen.protfolioSummary
                    ? icons.smallScreen
                    : icons.enterFullScreen
                }
                onClick={() => toogleFullScreen("protfolioSummary")}
              />
            </ViewReverse>
          </ViewReverse>

          {isDetailOpen.protfolioSummary ? (
            <>
              <ViewReverse className={"w-full gap-6"}>
                <div className="flex flex-col items-center w-4/5 py-1 bg-light-blue bg-opacity-20 rounded">
                  <Text
                    text={getContentKey("purchase_power")}
                    size={fontSizes.h14}
                    color="text-dark-blue"
                  />
                  <Text
                    text={portfolioSummary?.purchasePower?.toLocaleString()}
                    size={fontSizes.h22}
                    weight={TextWeight.bold}
                    color="text-dark-blue"
                  />
                </div>
                <div className="w-1/5 bg-grey flex justify-center items-center rounded">
                  <Text text="SAR" size={fontSizes.h14} />
                </div>
              </ViewReverse>

              <ViewReverse className={"w-full"}>
                <ViewItems className={"w-2/5 mt-4"}>
                  <Text
                    text={getContentKey("cash")}
                    color="text-light-blue"
                    size={fontSizes.h14}
                  />
                  <Text
                    text={portfolioSummary?.cash?.toLocaleString()}
                    color="text-dark-blue"
                    size={fontSizes.h14}
                  />
                  <Text
                    text={"buy_transit"}
                    color="text-light-blue"
                    size={fontSizes.h14}
                    className={"mt-3"}
                  />
                  <Text
                    text={portfolioSummary?.buyInTransit?.toLocaleString()}
                    color="text-dark-blue"
                    size={fontSizes.h14}
                  />
                  <Text
                    text={getContentKey("portfolio_value")}
                    color="text-light-blue"
                    size={fontSizes.h14}
                    className={"mt-3"}
                  />
                  <Text
                    text={portfolioSummary?.portfolioValue?.toLocaleString()}
                    color="text-dark-blue"
                    size={fontSizes.h14}
                  />
                </ViewItems>
                <ViewItems className={"mt-4"}>
                  <Text
                    text={getContentKey("blocked")}
                    color="text-light-blue"
                    size={fontSizes.h14}
                  />
                  <Text
                    text={portfolioSummary?.blocked?.toLocaleString()}
                    color="text-dark-blue"
                    size={fontSizes.h14}
                  />
                  <Text
                    text={getContentKey("limit")}
                    color="text-light-blue"
                    size={fontSizes.h14}
                    className={"mt-3"}
                  />
                  <Text
                    text={portfolioSummary?.limit?.toLocaleString()}
                    color="text-dark-blue"
                    size={fontSizes.h14}
                  />
                  <Text
                    text={getContentKey("coverage_ratio")}
                    color="text-light-blue"
                    size={fontSizes.h14}
                    className={"mt-3"}
                  />
                  <Text
                    text={portfolioSummary?.coverageRatio?.toLocaleString()}
                    color="text-dark-blue"
                    size={fontSizes.h14}
                  />
                  <Text
                    text={getContentKey("ltv_ratio")}
                    color="text-light-blue"
                    size={fontSizes.h14}
                    className={"mt-3"}
                  />
                  <Text
                    text={portfolioSummary?.ltvRatio?.toLocaleString()}
                    color="text-dark-blue"
                    size={fontSizes.h14}
                  />
                </ViewItems>
              </ViewReverse>

              <ViewReverse className={"gap-1 mt-6 mb-1"}>
                <Text
                  text={getContentKey("cash_account")}
                  color="text-primary-light"
                  size={fontSizes.h14}
                />
                <Text
                  text={portfolioSummary?.cash?.toLocaleString()}
                  color="text-primary-light"
                  weight={TextWeight.bold}
                  size={fontSizes.h14}
                />
              </ViewReverse>

              <div className="h-[2px] bg-grey w-full" />

              <ViewReverse className={"w-full"}>
                <ViewItems className={"w-2/5"}>
                  <Text
                    text={getContentKey("holding_value")}
                    color="text-light-blue"
                    size={fontSizes.h14}
                    className={"mt-3"}
                  />
                  <Text
                    text={portfolioSummary?.holdingValue?.toLocaleString()}
                    color="text-dark-blue"
                    size={fontSizes.h14}
                  />
                  <Text
                    text={getContentKey("unsettled_buy")}
                    color="text-light-blue"
                    size={fontSizes.h14}
                    className={"mt-3"}
                  />
                  <Text
                    text={portfolioSummary?.unsettledBuy?.toLocaleString()}
                    color="text-dark-blue"
                    size={fontSizes.h14}
                  />
                </ViewItems>
                <ViewItems>
                  <Text
                    text={getContentKey("acc_limit")}
                    color="text-light-blue"
                    size={fontSizes.h14}
                    className={"mt-3"}
                  />
                  <Text
                    text={portfolioSummary?.accountLimit?.toLocaleString()}
                    color="text-dark-blue"
                    size={fontSizes.h14}
                  />
                  <Text
                    text={getContentKey("unsettled_sell")}
                    color="text-light-blue"
                    size={fontSizes.h14}
                    className={"mt-3"}
                  />
                  <Text
                    text={portfolioSummary?.unsettledSell?.toLocaleString()}
                    color="text-dark-blue"
                    size={fontSizes.h14}
                  />
                </ViewItems>
              </ViewReverse>
            </>
          ) : null}
        </ViewItems>
      </ViewReverse>

      <ViewItems className={"border border-grey mt-6 rounded px-5 py-3 gap-2"}>
        <ViewReverse className={"w-full justify-between"}>
          <Text
            text={getContentKey("portfolio_position")}
            color="text-primary-light"
            size={fontSizes.h14}
          />
          <ViewReverse className={"gap-2 items-center"}>
            <div className="w-4 h-4 bg-grey rounded-full" />
            <ReactSVG src={icons.filter} />
            <ReactSVG src={icons.roundArrow} className="opacity-25" />
            <ReactSVG
              className="cursor-pointer"
              src={
                isDetailOpen.portfolioPosition
                  ? icons.smallScreen
                  : icons.enterFullScreen
              }
              onClick={() => toogleFullScreen("portfolioPosition")}
            />
          </ViewReverse>
        </ViewReverse>

        {isDetailOpen.portfolioPosition ? (
          <ConfigProvider
            theme={{
              components: {
                Table: {
                  headerBg: "#E0E0E0",
                  headerSplitColor: "transparent",
                  borderRadius: 0,
                  padding: 15,
                },
              },
            }}
          >
            <Table
              columns={
                isArabic
                  ? portfolioColumns(TextWeight, getContentKey)?.reverse()
                  : portfolioColumns(TextWeight, getContentKey)
              }
              dataSource={portfolioPostion}
              pagination={false}
              className="rounded-tr-[10px] rounded-tl-[10px] border-t-grey border overflow-hidden"
              scroll={{
                x: "max-content",
                y: 250,
              }}
              locale={{ emptyText: "No data available in table" }}
              onRow={(i) => ({
                // onClick: (e) => {
                // },
              })}
            />
          </ConfigProvider>
        ) : null}
      </ViewItems>

      <ViewItems
        className={"border border-grey mt-6 rounded px-5 py-3 gap-2 w-full"}
      >
        <ViewReverse className={"w-full justify-between"}>
          <Text
            text={getContentKey("order_basket")}
            color="text-primary-light"
            size={fontSizes.h14}
          />
          <ViewReverse className={"gap-2 items-center"}>
            <div className="w-4 h-4 bg-grey rounded-full" />
            <ReactSVG src={icons.filter} />
            <ReactSVG src={icons.roundArrow} className="opacity-25" />
            <ReactSVG
              className="cursor-pointer"
              src={
                isDetailOpen.orderBasket
                  ? icons.smallScreen
                  : icons.enterFullScreen
              }
              onClick={() => toogleFullScreen("orderBasket")}
            />
          </ViewReverse>
        </ViewReverse>

        <ConfigProvider
          tabs={{
            style: {
              color: "red",
            },
          }}
          theme={{
            components: {
              Tabs: {
                cardBg: "white",
                // colorBorderSecondary: 'transparent',
              },
            },
          }}
        >
          <Tabs
            defaultActiveKey="1"
            type="card"
            className="w-full"
            items={new Array(3).fill(null).map((_, i) => {
              const id = String(i + 1);
              return {
                label: `Card Tab ${id}`,
                key: id,
                // children: `Content of card tab ${id}`,
              };
            })}
            tabBarExtraContent={
              <ViewReverse className={"gap-2 mb-1"}>
                <Button
                  text={getContentKey("submit")}
                  color={"text-dark-grey"}
                  bgColor="bg-white"
                  className={
                    "border border-dark-grey w-32 px-0 text-center text-dark-grey text-opacity-30 border-opacity-30"
                  }
                  icon={icons.tick}
                  iconClassName="opacity-30 mb-1"
                />

                <Button
                  text={getContentKey("simulate")}
                  color={"text-dark-grey"}
                  bgColor="bg-white"
                  className={
                    "border border-dark-grey w-32 px-0 text-center text-dark-grey text-opacity-30 border-opacity-30"
                  }
                  icon={icons.play}
                  iconClassName="opacity-30 mb-1"
                />

                <Button
                  text={getContentKey("delete")}
                  color={"text-dark-grey"}
                  bgColor="bg-white"
                  className={
                    "border border-dark-grey w-32 px-0 text-center text-dark-grey text-opacity-30 border-opacity-30"
                  }
                  icon={icons.delete_icon}
                  iconClassName="opacity-30 mb-1"
                />
              </ViewReverse>
            }
          />
        </ConfigProvider>

        {isDetailOpen.orderBasket ? (
          <ConfigProvider
            theme={{
              components: {
                Table: {
                  headerBg: "#E0E0E0",
                  headerSplitColor: "transparent",
                  borderRadius: 0,
                  padding: 15,
                },
              },
            }}
          >
            <Table
              columns={
                isArabic
                  ? orderBasketColumns(TextWeight, getContentKey)?.reverse()
                  : orderBasketColumns(TextWeight, getContentKey)
              }
              dataSource={orderBasketList}
              pagination={false}
              className="rounded-tr-[10px] rounded-tl-[10px] border-t-grey border overflow-hidden"
              scroll={{
                x: "max-content",
                y: 250,
              }}
              rowSelection={{
                type: "checkbox",
              }}
              locale={{ emptyText: "No data available in table" }}
              onRow={(i) => ({
                // onClick: (e) => {
                // },
              })}
            />
          </ConfigProvider>
        ) : null}
      </ViewItems>

      <ViewItems className={"border border-grey mt-6 rounded px-5 py-3 gap-2"}>
        <ViewReverse className={"w-full justify-between"}>
          <Text
            text={getContentKey("transaction_ticket")}
            color="text-primary-light"
            size={fontSizes.h14}
          />
          <ViewReverse className={"gap-2 items-center"}>
            <div className="w-4 h-4 bg-grey rounded-full" />
            <ReactSVG src={icons.filter} />
            <ReactSVG src={icons.roundArrow} className="opacity-25" />
            <ReactSVG
              className="cursor-pointer"
              src={
                isDetailOpen.transactionTicket
                  ? icons.smallScreen
                  : icons.enterFullScreen
              }
              onClick={() => toogleFullScreen("transactionTicket")}
            />
          </ViewReverse>
        </ViewReverse>

        {isDetailOpen.transactionTicket ? (
          <ConfigProvider
            theme={{
              components: {
                Table: {
                  headerBg: "#E0E0E0",
                  headerSplitColor: "transparent",
                  borderRadius: 0,
                  padding: 15,
                },
              },
            }}
          >
            <Table
              columns={
                isArabic
                  ? orderBasketColumns(TextWeight, getContentKey)?.reverse()
                  : orderBasketColumns(TextWeight, getContentKey)
              }
              dataSource={orderBasketData}
              pagination={false}
              className="rounded-tr-[10px] rounded-tl-[10px] border-t-grey border overflow-hidden"
              scroll={{
                x: "max-content",
                y: 250,
              }}
              rowSelection={{
                type: "checkbox",
              }}
              locale={{ emptyText: "No data available in table" }}
              onRow={(i) => ({
                // onClick: (e) => {
                // },
              })}
            />
          </ConfigProvider>
        ) : null}
      </ViewItems>
    </div>
  );
};

export default TradingOverview;
