import apiServices from "../../ApiServices/request-handler";
import {
  setMarketSummary,
  setOrderBasketList,
  setPortfolioPosition,
  setPortfolioSummary,
  setWatchList,
} from "../slices/tradingSlice";

const getMarketSummary = () => {
  return async (dispatch) => {
    try {
      const response = await apiServices.getMarketSummary();
      dispatch(setMarketSummary(response?.result));
    } catch (error) {}
  };
};

const getPortfolioSummary = (userCode) => {
  return async (dispatch) => {
    try {
      const response = await apiServices.getPortfolioSummary(userCode);
      dispatch(setPortfolioSummary(response?.result));
    } catch (error) {}
  };
};

const getWatchList = () => {
  return async (dispatch) => {
    try {
      const response = await apiServices.getWatchList();
      dispatch(setWatchList(response?.result));
    } catch (error) {}
  };
};

const getPortfolioPosition = (userCode) => {
  return async (dispatch) => {
    try {
      const response = await apiServices.getPortfolioPosition("123456");
      console.log("resssssss", response);
      dispatch(setPortfolioPosition(response?.result));
    } catch (error) {
      console.log("errrrrr", error);
    }
  };
};

const getOrderBasketList = () => {
  return async (dispatch) => {
    try {
      const response = await apiServices.getOrderBasketList("123456");
      dispatch(setOrderBasketList(response?.result));
    } catch (error) {}
  };
};

export {
  getMarketSummary,
  getPortfolioSummary,
  getWatchList,
  getPortfolioPosition,
  getOrderBasketList,
};
