import React, { useState } from "react";
import Text from "../../../components/Text";
import dynamicfunction from "../../../utils/dynamicfunction";
import { fontSizes } from "../../../constants/constants";
import ViewReverse from "../../../components/ViewReverse";
import ViewItems from "../../../components/ViewItems";
import CustomDropdown from "../../../components/CustomDropdown";
import { transferColumns } from "../../../config/config";
import CustomInput from "../../../components/CustomInput";
import CustomTextArea from "../../../components/CustomTextArea";
import Button from "../../../components/Button";
import CustomDatePicker from "../../../components/CustomDatePicker";
import { ConfigProvider, Table } from "antd";
import { useDispatch, useSelector } from "react-redux";
import { createTransfer, getTransactions } from "../../../store/actions/transferAction";
import { rowReverseStylesm } from "../../../utils/styles";
import { getContentKeyAccToLang } from "../../../utils/utils";

const Transfer = () => {
  const dispatch = useDispatch();
  const { transferInfo } = useSelector((state) => state.transfer);
  const { transferContent } = useSelector((state) => state.content);

  const { TextWeight, isArabic } = dynamicfunction();

  const [transferData, setTransferData] = useState({
    fromAccountId: 0,
    toAccountId: 0,
    amount: 0,
    reasonId: 0,
    otherReason: "",
  });
  const [fromDate, setFromDate] = useState();
  const [toDate, setToDate] = useState();

  const getContentKey = (key, isColon = false) => {
    return getContentKeyAccToLang(
      transferContent?.web_transfer_tab?.data?.attributes,
      key,
      isArabic,
      isColon,
    );
  };

  const handleTransferData = (key, val) =>
    setTransferData((prev) => ({ ...prev, [key]: val }));

  const onSubmit = () => createTransfer(transferData);

  const onSearchTransactions = () => dispatch(getTransactions({fromDate, toDate}, transferInfo));

  return (
    <div>
      <Text
        text={getContentKey("heading")}
        weight={TextWeight.headingBold}
        size={fontSizes.h24}
      />

      <ViewItems className={"border border-grey mt-2 rounded px-5 py-3"}>
        <Text
          text={getContentKey("heading")}
          color="text-primary-light"
          size={fontSizes.h14}
          className={"mb-4"}
        />

        <div className="w-full bg-orange-light bg-opacity-20 rounded p-4 mb-4 md:w-1/2">
          <Text
            text={getContentKey("note")}
            color="text-black"
            size={fontSizes.h16}
            weight={TextWeight.light}
          />
        </div>

        <ViewReverse className={`w-full gap-10 flex-col ${rowReverseStylesm(isArabic)}`}>
          <CustomDropdown
            label={getContentKey("from_account")}
            options={transferInfo?.fromAccountList ? transferInfo?.fromAccountList : []}
            selectedValue={transferData.fromAccountId}
            onSelect={(val) => handleTransferData("fromAccountId", val)}
            className={"w-full md:w-1/2"}
          />

          <ViewReverse className={"gap-10"}>
            <div>
              <Text
                text={getContentKey("amount")}
                color="text-primary-light"
                size={fontSizes.h14}
              />

              <Text
                text={transferInfo?.balance?.slice(
                  0,
                  transferInfo?.balance.length - 3
                )}
                size={fontSizes.h14}
                weight={TextWeight.light}
                className={"mt-4"}
              />
            </div>
            <div>
              <Text
                text={getContentKey("currency")}
                color="text-primary-light"
                size={fontSizes.h14}
              />

              <Text
                text={transferInfo?.currency}
                size={fontSizes.h14}
                weight={TextWeight.light}
                className={"mt-4"}
              />
            </div>
          </ViewReverse>
        </ViewReverse>

        <ViewReverse className={"w-full gap-10 mt-4"}>
          <CustomDropdown
            label={getContentKey("to_account")}
            options={transferInfo?.toAccountList ? transferInfo?.toAccountList : []}
            selectedValue={transferData.toAccountId}
            onSelect={(val) => handleTransferData("toAccountId", val)}
            className={"w-full md:w-1/2"}
          />

          <div>
            <Text
              text="Currency"
              color="text-primary-light"
              size={fontSizes.h14}
            />

            <Text
              text={transferInfo?.currency}
              size={fontSizes.h14}
              weight={TextWeight.light}
              className={"mt-4"}
            />
          </div>
        </ViewReverse>

        <Text
          text={isArabic ? " + " + getContentKey("add_beneficiary") : getContentKey("add_beneficiary") + " + "}
          color="text-light-blue"
          size={fontSizes.h14}
          className={"mt-2 ms-4 me-4 mb-3"}
        />

        <ViewReverse className={`w-full gap-10 flex-col ${rowReverseStylesm(isArabic)}`}>
          <ViewReverse className={"gap-4 w-full md:w-1/2"}>
            <CustomInput
              label={getContentKey("amount")}
              placeholder={"0"}
              isFullborder
              value={transferData.amount}
              onChange={(e) => handleTransferData('amount', e.target.value)}
              isRounded
              type="number"
            />

            <CustomDropdown
              label={getContentKey("currency")}
              options={transferInfo?.toCurrencyList ? transferInfo?.toCurrencyList : []}
              selectedValue={transferData.amount}
              onSelect={(val) => handleTransferData("amount", val)}
              width="w-1/3"
            />
          </ViewReverse>

          <ViewReverse className={`gap-10 flex-col ${rowReverseStylesm(isArabic)}`}>
            <div>
              <Text
                text={getContentKey("exchange_rate")}
                color="text-primary-light"
                size={fontSizes.h14}
              />

              <Text
                text={transferInfo?.exchangeRate?.toLocaleString()}
                size={fontSizes.h14}
                weight={TextWeight.light}
                className={"mt-4"}
              />
            </div>
            <div>
              <Text
                text={getContentKey("remaining_balance")}
                color="text-primary-light"
                size={fontSizes.h14}
              />

              <Text
                text={transferInfo?.remainingBalance?.toLocaleString()}
                size={fontSizes.h14}
                weight={TextWeight.light}
                className={"mt-4"}
              />
            </div>
            <div>
              <Text
                text={getContentKey("equivalent_value")}
                color="text-primary-light"
                size={fontSizes.h14}
              />

              <Text
                text={transferInfo?.equivalentValue?.toLocaleString()}
                size={fontSizes.h14}
                weight={TextWeight.light}
                className={"mt-4"}
              />
            </div>
          </ViewReverse>
        </ViewReverse>

        <CustomDropdown
          label={getContentKey("reason")}
          options={transferInfo?.reasonList ? transferInfo?.reasonList : []}
          selectedValue={transferData.reasonId}
          onSelect={(val) => handleTransferData("reasonId", val)}
          className={"my-4 w-full md:w-1/2"}
        />

        <div className={"w-full md:w-1/2"}>
          <CustomTextArea
            label={getContentKey("other_reason")}
            placeholder={getContentKey("add_info")}
            isFullborder
            isRounded
            rows={4}
            value={transferData.otherReason}
            onChange={(e) => handleTransferData("otherReason", e.target.value)}
            className={""}
          />
        </div>

        <ViewReverse className="items-center w-full justify-end gap-2 mt-8 mb-6">
          <Button
            text={getContentKey("transfer_btn")}
            bgColor="bg-orange"
            className={"uppercase w-32 px-0 text-center border border-orange"}
            onClick={(onSubmit)}
          />
          <Button
            text={getContentKey("clear_btn")}
            color={"text-dark-grey"}
            bgColor="bg-white"
            className={
              "uppercase border border-dark-grey w-32 px-0 text-center border-opacity-50"
            }
          />
        </ViewReverse>
      </ViewItems>

      <ViewItems className={"border border-grey rounded px-5 py-3 mt-6"}>
        <Text
          text={getContentKey("transaction_date")}
          color="text-primary-light"
          size={fontSizes.h14}
          className={"mb-3"}
        />

        <ViewReverse className={"gap-4 w-full items-end mb-5"}>
          <CustomDatePicker
            label={getContentKey("from_date")}
            onChange={(date, dateString) => setFromDate(dateString)}
            width="w-full sm:w-1/3"
          />
          <CustomDatePicker
            label={getContentKey("to_date")}
            onChange={(date, dateString) => setToDate(dateString)}
            width="w-full sm:w-1/3"
          />
          <Button
            text={getContentKey("search")}
            bgColor="bg-orange"
            className={
              "uppercase w-32 px-0 text-center border border-orange mb-1"
            }
              onClick={onSearchTransactions}
          />
        </ViewReverse>

        <ConfigProvider
          theme={{
            components: {
              Table: {
                headerBg: "#E0E0E0",
                headerSplitColor: "transparent",
                borderRadius: 0,
                padding: 15,
              },
            },
          }}
        >
          <Table
            columns={ isArabic ? transferColumns(TextWeight, getContentKey)?.reverse() : transferColumns(TextWeight, getContentKey)}
            dataSource={
              transferInfo?.transactionList?.length > 0
                ? transferInfo?.transactionList
                : null
            }
            pagination={false}
            className="rounded-tr-[10px] rounded-tl-[10px] border-t-grey border overflow-hidden"
            scroll={{
              x: "max-content",
            }}
            locale={{ emptyText: getContentKey("no_data") }}
            onRow={(i) => ({
              // onClick: (e) => {
              // },
            })}
          />
        </ConfigProvider>
      </ViewItems>
    </div>
  );
};

export default Transfer;
