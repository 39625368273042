import React, { useState } from "react";
import ViewReverse from "../../../components/ViewReverse";
import dynamicfunction from "../../../utils/dynamicfunction";
import { fontSizes } from "../../../constants/constants";
import Text from "../../../components/Text";
import Button from "../../../components/Button";
import ViewItems from "../../../components/ViewItems";
import { ReactSVG } from "react-svg";
import icons from "../../../constants/icons";
import { ConfigProvider, Table, Tabs } from "antd";
import {
  orderDetailColumns,
  orderDetailData,
  orderDetailsTabs,
  stockPositionColumns,
  stockPositionData,
} from "../../../config/config";
import BuyModal from "../../../components/BuyModal";
import { rowReverseStylesm } from "../../../utils/styles";
import { getContentKeyAccToLang } from "../../../utils/utils";
import { useSelector } from "react-redux";

const SubAccounts = () => {
  const { tradingContent } = useSelector((state) => state.content);
  const { TextWeight, isArabic } = dynamicfunction();

  const [isDetailOpen, setIsDetailOpen] = useState({
    tadawulPurchase: true,
    tadawulMarket: true,
    stockPosition: true,
    orderDetail: true,
  });
  const [isBuyModal, setIsBuyModal] = useState(false);

  const toogleFullScreen = (key) =>
    setIsDetailOpen((prev) => ({
      ...prev,
      [key]: !prev[key],
    }));

  const getContentKey = (key) => {
    return getContentKeyAccToLang(
      tradingContent?.web_trading_subaccount?.data?.attributes,
      key,
      isArabic
    );
  };

  return (
    <div>
      <ViewReverse className={"justify-between"}>
        <Text
          text={getContentKey("sub_accounts")}
          weight={TextWeight.headingBold}
          size={fontSizes.h24}
          color="text-dark-blue"
        />

        <ViewReverse className={"gap-3 relative"}>
          <ViewReverse className={"items-center gap-2 me-3"}>
            <div className="h-4 w-4 rounded-full bg-green-500" />
            <Text
              text={getContentKey("connected")}
              className={"uppercase opacity-50"}
              size={fontSizes.h10}
              weight={TextWeight.bold}
              color="text-dark-grey"
            />
          </ViewReverse>
          <Button
            text={getContentKey("buy")}
            bgColor="bg-light-blue"
            weight={TextWeight.extraBold}
            className={"px-5"}
            onClick={() => setIsBuyModal(!isBuyModal)}
          />
          <BuyModal
            isOpen={isBuyModal}
            onClose={() => setIsBuyModal(!isBuyModal)}
          />
          <Button
            text={getContentKey("sell")}
            bgColor="bg-dark-blue"
            weight={TextWeight.extraBold}
            className={"px-5"}
          />
        </ViewReverse>
      </ViewReverse>

      <ViewReverse className={`gap-5 flex-col ${rowReverseStylesm(isArabic)}`}>
        <ViewItems className={"w-full sm:w-1/2 gap-6"}>
          <ViewItems
            className={"w-full border border-grey mt-6 rounded px-5 py-3 pb-10"}
          >
            <Text
              text={getContentKey("portfolio_summary")}
              color="text-primary-light"
              size={fontSizes.h14}
              className={"mb-3"}
            />

            <ViewReverse className={"gap-10"}>
              <ViewItems>
                <Text
                  text="483,879.00"
                  color="text-dark-blue"
                  weight={TextWeight.light}
                  size={fontSizes.h18}
                />
                <Text
                  text={getContentKey("market_value")}
                  color="text-dark-blue"
                  size={fontSizes.h14}
                  weight={TextWeight.bold}
                />
              </ViewItems>
              <ViewItems>
                <Text
                  text={getContentKey("purchase_power")}
                  color="text-dark-grey"
                  weight={TextWeight.light}
                  size={fontSizes.h14}
                  className={"opacity-50"}
                />
                <Text
                  text="8,448,562.87"
                  color="text-dark-blue"
                  weight={TextWeight.light}
                  size={fontSizes.h14}
                  className={"mb-3"}
                />
                <Text
                  text={getContentKey("blocked_amount")}
                  color="text-dark-grey"
                  weight={TextWeight.light}
                  size={fontSizes.h14}
                  className={"opacity-50"}
                />
                <Text
                  text="1,551,461.11"
                  color="text-dark-blue"
                  weight={TextWeight.light}
                  size={fontSizes.h14}
                />
              </ViewItems>
              <ViewItems>
                <Text
                  text={getContentKey("cash_balance")}
                  color="text-dark-grey"
                  weight={TextWeight.light}
                  size={fontSizes.h14}
                  className={"opacity-50"}
                />
                <Text
                  text="8,448,562.87"
                  color="text-dark-blue"
                  weight={TextWeight.light}
                  size={fontSizes.h14}
                  className={"mb-3"}
                />
                <Text
                  text={getContentKey("currency")}
                  color="text-dark-grey"
                  weight={TextWeight.light}
                  size={fontSizes.h14}
                  className={"opacity-50"}
                />
                <Text
                  text="SAR"
                  color="text-dark-blue"
                  weight={TextWeight.light}
                  size={fontSizes.h14}
                />
              </ViewItems>
            </ViewReverse>
          </ViewItems>

          <ViewItems className={"w-full border border-grey rounded px-5 py-3"}>
            <ViewReverse className={"w-full items-center justify-between mb-3"}>
              <Text
                text={getContentKey("tawadul")}
                color="text-primary-light"
                size={fontSizes.h14}
              />
              <ViewReverse className={"gap-2 items-center"}>
                <div className="w-4 h-4 bg-green-500 rounded-full" />
                <ReactSVG src={icons.filter} />
                <ReactSVG src={icons.roundArrow} className="opacity-25" />
                <ReactSVG
                  className="cursor-pointer"
                  src={
                    isDetailOpen.tadawulMarket
                      ? icons.smallScreen
                      : icons.enterFullScreen
                  }
                  onClick={() => toogleFullScreen("tadawulMarket")}
                />
              </ViewReverse>
            </ViewReverse>

            {isDetailOpen.tadawulMarket && (
              <>
                <div className="flex flex-col items-center w-full py-1 bg-light-blue bg-opacity-20 rounded">
                  <Text
                    text={getContentKey("total_market_value")}
                    size={fontSizes.h14}
                    color="text-dark-blue"
                  />
                  <Text
                    text="448,562.87"
                    size={fontSizes.h22}
                    weight={TextWeight.bold}
                    color="text-dark-blue"
                  />
                </div>
                <ViewReverse className={"w-full p-4 justify-around"}>
                  <div className="flex flex-col items-center p-4">
                    <Text
                      text={getContentKey("total_gain_loss")}
                      size={fontSizes.h14}
                      color="text-light-blue"
                    />
                    <Text
                      text="-1,011,246.53"
                      size={fontSizes.h16}
                      weight={TextWeight.bold}
                      color="text-dark-blue"
                      className={"mb-4"}
                    />
                    <Text
                      text={getContentKey("account_type")}
                      size={fontSizes.h14}
                      color="text-light-blue"
                    />
                    <Text
                      text={getContentKey("trading")}
                      size={fontSizes.h16}
                      weight={TextWeight.bold}
                      color="text-dark-blue"
                    />
                  </div>
                  <div className="w-[2px] h-full bg-grey" />
                  <div className="flex flex-col items-center p-4">
                    <Text
                      text={getContentKey("total_cost")}
                      size={fontSizes.h14}
                      color="text-light-blue"
                    />
                    <Text
                      text="1,495,125.53"
                      size={fontSizes.h16}
                      weight={TextWeight.bold}
                      color="text-dark-blue"
                      className={"mb-4"}
                    />
                    <Text
                      text={getContentKey("custody_type")}
                      size={fontSizes.h14}
                      color="text-light-blue"
                    />
                    <Text
                      text={getContentKey("regular_custody")}
                      size={fontSizes.h16}
                      weight={TextWeight.bold}
                      color="text-dark-blue"
                    />
                  </div>
                </ViewReverse>
              </>
            )}
          </ViewItems>
        </ViewItems>

        <ViewItems
          className={
            "w-full sm:w-1/2 border border-grey mt-6 rounded px-5 py-3"
          }
        >
          <ViewReverse className={"w-full items-center justify-between mb-3"}>
            <Text
              text={getContentKey("tawadul")}
              color="text-primary-light"
              size={fontSizes.h14}
            />
            <ViewReverse className={"gap-2 items-center"}>
              <div className="w-4 h-4 bg-green-500 rounded-full" />
              <ReactSVG src={icons.filter} />
              <ReactSVG src={icons.roundArrow} className="opacity-25" />
              <ReactSVG
                className="cursor-pointer"
                src={
                  isDetailOpen.tadawulPurchase
                    ? icons.smallScreen
                    : icons.enterFullScreen
                }
                onClick={() => toogleFullScreen("tadawulPurchase")}
              />
            </ViewReverse>
          </ViewReverse>

          {isDetailOpen.tadawulPurchase && (
            <>
              <ViewReverse className={"w-full gap-6"}>
                <div className="flex flex-col items-center w-4/5 py-1 bg-light-blue bg-opacity-20 rounded">
                  <Text
                    text={getContentKey("purchase_power")}
                    size={fontSizes.h14}
                    color="text-dark-blue"
                  />
                  <Text
                    text="8,448,562.87"
                    size={fontSizes.h22}
                    weight={TextWeight.bold}
                    color="text-dark-blue"
                  />
                </div>
                <div className="w-1/5 bg-grey flex justify-center items-center rounded">
                  <Text text="SAR" size={fontSizes.h14} />
                </div>
              </ViewReverse>
              <ViewReverse className={"w-full"}>
                <ViewItems className={"w-2/5 mt-4"}>
                  <Text
                    text={getContentKey("cash")}
                    color="text-light-blue"
                    size={fontSizes.h14}
                  />
                  <Text
                    text="8,448,562.87"
                    color="text-dark-blue"
                    size={fontSizes.h14}
                  />
                  <Text
                    text={getContentKey("buy_transit")}
                    color="text-light-blue"
                    size={fontSizes.h14}
                    className={"mt-3"}
                  />
                  <Text
                    text="8,448,562.87"
                    color="text-dark-blue"
                    size={fontSizes.h14}
                  />
                  <Text
                    text={getContentKey("portfolio_value")}
                    color="text-light-blue"
                    size={fontSizes.h14}
                    className={"mt-3"}
                  />
                  <Text
                    text="8,448,562.87"
                    color="text-dark-blue"
                    size={fontSizes.h14}
                  />
                </ViewItems>
                <ViewItems className={"mt-4"}>
                  <Text
                    text={getContentKey("blocked")}
                    color="text-light-blue"
                    size={fontSizes.h14}
                  />
                  <Text
                    text="8,448,562.87"
                    color="text-dark-blue"
                    size={fontSizes.h14}
                  />
                  <Text
                    text={getContentKey("limit")}
                    color="text-light-blue"
                    size={fontSizes.h14}
                    className={"mt-3"}
                  />
                  <Text
                    text="0.00"
                    color="text-dark-blue"
                    size={fontSizes.h14}
                  />
                  <Text
                    text={getContentKey("coverage_ratio")}
                    color="text-light-blue"
                    size={fontSizes.h14}
                    className={"mt-3"}
                  />
                  <Text
                    text="0.00"
                    color="text-dark-blue"
                    size={fontSizes.h14}
                  />
                  <Text
                    text={getContentKey("ltv_ratio")}
                    color="text-light-blue"
                    size={fontSizes.h14}
                    className={"mt-3"}
                  />
                  <Text
                    text="0.00"
                    color="text-dark-blue"
                    size={fontSizes.h14}
                  />
                </ViewItems>
              </ViewReverse>
              <ViewReverse className={"gap-1 mt-6 mb-1"}>
                <Text
                  text={getContentKey("cash_account")}
                  color="text-primary-light"
                  size={fontSizes.h14}
                />
                <Text
                  text="1234567890"
                  color="text-primary-light"
                  weight={TextWeight.bold}
                  size={fontSizes.h14}
                />
              </ViewReverse>

              <div className="h-[2px] bg-grey w-full" />

              <ViewReverse className={"w-full"}>
                <ViewItems className={"w-2/5"}>
                  <Text
                    text={getContentKey("holding_value")}
                    color="text-light-blue"
                    size={fontSizes.h14}
                    className={"mt-3"}
                  />
                  <Text
                    text="8,448,562.87"
                    color="text-dark-blue"
                    size={fontSizes.h14}
                  />
                  <Text
                    text={getContentKey("unsettled_buy")}
                    color="text-light-blue"
                    size={fontSizes.h14}
                    className={"mt-3"}
                  />
                  <Text
                    text="8,448,562.87"
                    color="text-dark-blue"
                    size={fontSizes.h14}
                  />
                </ViewItems>
                <ViewItems>
                  <Text
                    text={getContentKey("acc_limit")}
                    color="text-light-blue"
                    size={fontSizes.h14}
                    className={"mt-3"}
                  />
                  <Text
                    text="0.00"
                    color="text-dark-blue"
                    size={fontSizes.h14}
                  />
                  <Text
                    text={getContentKey("unsettled_sell")}
                    color="text-light-blue"
                    size={fontSizes.h14}
                    className={"mt-3"}
                  />
                  <Text
                    text="8,448,562.87"
                    color="text-dark-blue"
                    size={fontSizes.h14}
                  />
                </ViewItems>
              </ViewReverse>
            </>
          )}
        </ViewItems>
      </ViewReverse>

      <ViewItems className={"border border-grey mt-6 rounded px-5 py-3 gap-2"}>
        <ViewReverse className={"w-full justify-between"}>
          <Text
            text={getContentKey("tawadul")}
            color="text-primary-light"
            size={fontSizes.h14}
          />
          <ViewReverse className={"gap-2 items-center"}>
            <div className="w-4 h-4 bg-grey rounded-full" />
            <ReactSVG src={icons.filter} />
            <ReactSVG src={icons.roundArrow} className="opacity-25" />
            <ReactSVG
              className="cursor-pointer"
              src={
                isDetailOpen.stockPosition
                  ? icons.smallScreen
                  : icons.enterFullScreen
              }
              onClick={() => toogleFullScreen("stockPosition")}
            />
          </ViewReverse>
        </ViewReverse>

        {isDetailOpen.stockPosition && (
          <ConfigProvider
            theme={{
              components: {
                Table: {
                  headerBg: "#E0E0E0",
                  headerSplitColor: "transparent",
                  borderRadius: 0,
                  padding: 15,
                },
              },
            }}
          >
            <Table
              columns={
                isArabic
                  ? stockPositionColumns(TextWeight, getContentKey)?.reverse()
                  : stockPositionColumns(TextWeight, getContentKey)
              }
              dataSource={stockPositionData}
              pagination={false}
              className="rounded-tr-[10px] rounded-tl-[10px] border-t-grey border overflow-hidden"
              scroll={{
                x: "max-content",
                y: 250,
              }}
              locale={{ emptyText: "No data available in table" }}
              onRow={(i) => ({
                // onClick: (e) => {
                // },
              })}
            />
          </ConfigProvider>
        )}
      </ViewItems>

      <ViewItems className={"border border-grey mt-6 rounded px-5 py-3 gap-2"}>
        <ViewReverse className={"w-full justify-between"}>
          <Text
            text={getContentKey("orders_detail")}
            color="text-primary-light"
            size={fontSizes.h14}
          />
          <ViewReverse className={"gap-2 items-center"}>
            <div className="w-4 h-4 bg-grey rounded-full" />
            <ReactSVG src={icons.filter} />
            <ReactSVG src={icons.roundArrow} className="opacity-25" />
            <ReactSVG
              className="cursor-pointer"
              src={
                isDetailOpen.orderDetail
                  ? icons.smallScreen
                  : icons.enterFullScreen
              }
              onClick={() => toogleFullScreen("orderDetail")}
            />
          </ViewReverse>
        </ViewReverse>

        {isDetailOpen.orderDetail && (
          <>
            <ConfigProvider
              tabs={{
                style: {
                  color: "red",
                },
              }}
              theme={{
                components: {
                  Tabs: {
                    cardBg: "white",
                    // colorBorderSecondary: 'transparent',
                  },
                },
              }}
            >
              <Tabs
                defaultActiveKey="1"
                type="card"
                className="w-full"
                items={orderDetailsTabs(getContentKey)}
              />
            </ConfigProvider>

            <ConfigProvider
              theme={{
                components: {
                  Table: {
                    headerBg: "#E0E0E0",
                    headerSplitColor: "transparent",
                    borderRadius: 0,
                    padding: 15,
                  },
                },
              }}
            >
              <Table
                columns={
                  isArabic
                    ? orderDetailColumns(TextWeight, getContentKey)?.reverse()
                    : orderDetailColumns(TextWeight, getContentKey)
                }
                dataSource={orderDetailData}
                pagination={false}
                className="rounded-tr-[10px] rounded-tl-[10px] border-t-grey border overflow-hidden"
                scroll={{
                  x: "max-content",
                  y: 250,
                }}
                locale={{ emptyText: "No data available in table" }}
                onRow={(i) => ({
                  // onClick: (e) => {
                  // },
                })}
              />
            </ConfigProvider>
          </>
        )}
      </ViewItems>
    </div>
  );
};

export default SubAccounts;
