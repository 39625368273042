import React, { useState } from "react";
import ViewReverse from "../../../components/ViewReverse";
import Text from "../../../components/Text";
import dynamicfunction from "../../../utils/dynamicfunction";
import { fontSizes } from "../../../constants/constants";
import Button from "../../../components/Button";
import ViewItems from "../../../components/ViewItems";
import icons from "../../../constants/icons";
import { ConfigProvider, Table, Tabs } from "antd";
import {
  orderBasketColumns,
  orderBasketData,
  orderDetailColumns,
  orderDetailData,
  orderDetailsTabs,
  outstandingOrders,
} from "../../../config/config";
import { ReactSVG } from "react-svg";
import BuyModal from "../../../components/BuyModal";
import OrderDetails from "./OrderDetails";
import EditOrder from "./EditOrder";
import { rowReverseStylemd } from "../../../utils/styles";
import { useSelector } from "react-redux";
import { getContentKeyAccToLang } from "../../../utils/utils";

const OrderList = () => {
  const { tradingContent } = useSelector(state => state.content);
  const { TextWeight, isArabic } = dynamicfunction();

  const [isDetailOpen, setIsDetailOpen] = useState({
    outstandingOrders: true,
    filledOrders: true,
    cancelledOrders: true,
    orderDetail: true,
    orderBasket: true,
  });
  const [isBuyModal, setIsBuyModal] = useState(false);
  const [openOrderDetail, setOpenOrderDetail] = useState(false);
  const [isEditOrder, setIsEditOrder] = useState(false);

  const toogleFullScreen = (key) =>
    setIsDetailOpen((prev) => ({
      ...prev,
      [key]: !prev[key],
    }));

    const getContentKey = (key) => {
      return getContentKeyAccToLang(
        tradingContent?.web_trading_order?.data?.attributes,
        key,
        isArabic
      );
    };

  return (
    <div>
      <ViewReverse className={"justify-between"}>
        <ViewReverse className={"items-end gap-1"}>
          <Text
            text={getContentKey("order_list")}
            weight={TextWeight.headingBold}
            size={fontSizes.h24}
            color="text-dark-blue"
          />
          {openOrderDetail ? (
            <>
              <ReactSVG src={icons.rightAngle} className="mb-[10px]" />
              <Text
                text={`8000866041 ${getContentKey("order_detail")}`}
                size={fontSizes.h14}
                color="text-dark-blue"
                className={"mb-1"}
              />
            </>
          ) : isEditOrder ? (
            <>
              <ReactSVG src={icons.rightAngle} className="mb-[10px]" />
              <Text
                text={`8000866041 ${getContentKey("order_detail")}`}
                size={fontSizes.h14}
                color="text-dark-blue"
                className={"mb-1"}
              />
              <ReactSVG src={icons.rightAngle} className="mb-[10px]" />
              <Text
                text={getContentKey("edit_order")}
                size={fontSizes.h14}
                color="text-dark-blue"
                className={"mb-1"}
              />
            </>
          ) : null}
        </ViewReverse>

        <ViewReverse className={"gap-3 relative"}>
          <ViewReverse className={"items-center gap-2 me-3"}>
            <div className="h-4 w-4 rounded-full bg-green-500" />
            <Text
              text={getContentKey("connected")}
              className={"uppercase opacity-50"}
              size={fontSizes.h10}
              weight={TextWeight.bold}
              color="text-dark-grey"
            />
          </ViewReverse>
          <Button
            text={getContentKey("buy")}
            bgColor="bg-light-blue"
            weight={TextWeight.extraBold}
            className={"px-5"}
            onClick={() => setIsBuyModal(!isBuyModal)}
          />
          <BuyModal
            isOpen={isBuyModal}
            onClose={() => setIsBuyModal(!isBuyModal)}
            onClickDetailed={() => {
              setIsEditOrder(!isEditOrder);
              setIsBuyModal(!isBuyModal);
            }}
          />
          <Button
            text={getContentKey("sell")}
            bgColor="bg-dark-blue"
            weight={TextWeight.extraBold}
            className={"px-5"}
          />
        </ViewReverse>
      </ViewReverse>

      {openOrderDetail ? (
        <OrderDetails />
      ) : isEditOrder ? (
        <EditOrder/>
      ) : (
        <>
          <ViewReverse className={`w-full justify-between gap-4 flex-col ${rowReverseStylemd()}`}>
            <ViewItems
              className={"w-full md:w-[32%] border border-grey mt-6 rounded px-5 py-3"}
            >
              <ViewReverse className={"w-full justify-between"}>
                <Text
                  text={getContentKey("outstanding_orders")}
                  color="text-primary-light"
                  size={fontSizes.h14}
                />
                <ViewReverse className={"gap-2 items-center"}>
                  <ReactSVG src={icons.filter} />
                  <ReactSVG src={icons.roundArrow} className="opacity-25" />
                  <ReactSVG
                    className="cursor-pointer"
                    src={
                      isDetailOpen.outstandingOrders
                        ? icons.smallScreen
                        : icons.enterFullScreen
                    }
                    onClick={() => toogleFullScreen("outstandingOrders")}
                  />
                </ViewReverse>
              </ViewReverse>

              {isDetailOpen.outstandingOrders && (
                <ViewItems className={"mt-2 w-full max-h-96 overflow-scroll"}>
                  {outstandingOrders.map((item, index) => (
                    <ViewReverse
                      className={
                        "justify-between gap-8 border-b border-b-grey px-3 py-2 cursor-pointer"
                      }
                      onClick={() => setOpenOrderDetail(true)}
                    >
                      <ViewReverse className={"min-w-40 items-start gap-2"}>
                        <div
                          className={`px-4 py-1 rounded ${
                            index % 2 == 0 ? "bg-dark-blue" : "bg-light-blue"
                          }`}
                        >
                          <Text
                            text={item.id}
                            weight={TextWeight.bold}
                            color="text-white"
                          />
                        </div>
                        <ViewItems>
                          <Text
                            text={item.orderNo}
                            size={fontSizes.h14}
                            color="text-dark-blue"
                          />
                          <Text text={item.bank} color="text-light-blue" />
                        </ViewItems>
                      </ViewReverse>

                      <Text
                        text={item.type}
                        color={
                          item.type === "Buy"
                            ? "text-green-500"
                            : "text-red-500"
                        }
                      />

                      <ViewReverse className={"gap-2"}>
                        <ViewItems>
                          <Text
                            text={item.email}
                            size={fontSizes.h14}
                            color="text-dark-blue"
                          />
                          <Text text={item.status} color="text-light-blue" />
                        </ViewItems>
                        <ReactSVG src={icons.dots} />
                      </ViewReverse>
                    </ViewReverse>
                  ))}
                </ViewItems>
              )}
            </ViewItems>

            <ViewItems
              className={"w-full md:w-[32%] border border-grey mt-6 rounded px-5 py-3"}
            >
              <ViewReverse className={"w-full justify-between"}>
                <Text
                  text={getContentKey("filled_orders")}
                  color="text-primary-light"
                  size={fontSizes.h14}
                />
                <ViewReverse className={"gap-2 items-center"}>
                  <ReactSVG src={icons.filter} />
                  <ReactSVG src={icons.roundArrow} className="opacity-25" />
                  <ReactSVG
                    className="cursor-pointer"
                    src={
                      isDetailOpen.filledOrders
                        ? icons.smallScreen
                        : icons.enterFullScreen
                    }
                    onClick={() => toogleFullScreen("filledOrders")}
                  />
                </ViewReverse>
              </ViewReverse>

              {isDetailOpen.filledOrders && (
                <ViewItems className={"mt-2 w-full max-h-96 overflow-scroll"}>
                  {outstandingOrders.map((item, index) => (
                    <ViewReverse
                      className={
                        "justify-between gap-8 border-b border-b-grey px-3 py-2"
                      }
                    >
                      <ViewReverse className={"min-w-40 items-start gap-2"}>
                        <div
                          className={`px-4 py-1 rounded ${
                            index % 2 == 0 ? "bg-dark-blue" : "bg-light-blue"
                          }`}
                        >
                          <Text
                            text={item.id}
                            weight={TextWeight.bold}
                            color="text-white"
                          />
                        </div>
                        <ViewItems>
                          <Text
                            text={item.orderNo}
                            size={fontSizes.h14}
                            color="text-dark-blue"
                          />
                          <Text text={item.bank} color="text-light-blue" />
                        </ViewItems>
                      </ViewReverse>

                      <Text
                        text={item.type}
                        color={
                          item.type === "Buy"
                            ? "text-green-500"
                            : "text-red-500"
                        }
                      />

                      <ViewReverse className={"gap-2"}>
                        <ViewItems>
                          <Text
                            text={item.email}
                            size={fontSizes.h14}
                            color="text-dark-blue"
                          />
                          <Text text={item.status} color="text-light-blue" />
                        </ViewItems>
                        <ReactSVG src={icons.dots} />
                      </ViewReverse>
                    </ViewReverse>
                  ))}
                </ViewItems>
              )}
            </ViewItems>

            <ViewItems
              className={"w-full md:w-[32%] border border-grey mt-6 rounded px-5 py-3"}
            >
              <ViewReverse className={"w-full justify-between"}>
                <Text
                  text={getContentKey("cancelled_orders")}
                  color="text-primary-light"
                  size={fontSizes.h14}
                />
                <ViewReverse className={"gap-2 items-center"}>
                  <ReactSVG src={icons.filter} />
                  <ReactSVG src={icons.roundArrow} className="opacity-25" />
                  <ReactSVG
                    className="cursor-pointer"
                    src={
                      isDetailOpen.cancelledOrders
                        ? icons.smallScreen
                        : icons.enterFullScreen
                    }
                    onClick={() => toogleFullScreen("cancelledOrders")}
                  />
                </ViewReverse>
              </ViewReverse>

              {isDetailOpen.cancelledOrders && (
                <ViewItems className={"mt-2 w-full max-h-96 overflow-scroll"}>
                  {outstandingOrders.map((item, index) => (
                    <ViewReverse
                      className={
                        "justify-between gap-8 border-b border-b-grey px-3 py-2"
                      }
                    >
                      <ViewReverse className={"min-w-40 items-start gap-2"}>
                        <div
                          className={`px-4 py-1 rounded ${
                            index % 2 == 0 ? "bg-dark-blue" : "bg-light-blue"
                          }`}
                        >
                          <Text
                            text={item.id}
                            weight={TextWeight.bold}
                            color="text-white"
                          />
                        </div>
                        <ViewItems>
                          <Text
                            text={item.orderNo}
                            size={fontSizes.h14}
                            color="text-dark-blue"
                          />
                          <Text text={item.bank} color="text-light-blue" />
                        </ViewItems>
                      </ViewReverse>

                      <Text
                        text={item.type}
                        color={
                          item.type === "Buy"
                            ? "text-green-500"
                            : "text-red-500"
                        }
                      />

                      <ViewReverse className={"gap-2"}>
                        <ViewItems>
                          <Text
                            text={item.email}
                            size={fontSizes.h14}
                            color="text-dark-blue"
                          />
                          <Text text={item.status} color="text-light-blue" />
                        </ViewItems>
                        <ReactSVG src={icons.dots} />
                      </ViewReverse>
                    </ViewReverse>
                  ))}
                </ViewItems>
              )}
            </ViewItems>
          </ViewReverse>

          <ViewItems
            className={"border border-grey mt-6 rounded px-5 py-3 gap-2"}
          >
            <ViewReverse className={"w-full justify-between"}>
              <Text
                text={getContentKey("orders_detail")}
                color="text-primary-light"
                size={fontSizes.h14}
              />
              <ViewReverse className={"gap-2 items-center"}>
                <div className="w-4 h-4 bg-grey rounded-full" />
                <ReactSVG src={icons.filter} />
                <ReactSVG src={icons.roundArrow} className="opacity-25" />
                <ReactSVG
                  className="cursor-pointer"
                  src={
                    isDetailOpen.orderDetail
                      ? icons.smallScreen
                      : icons.enterFullScreen
                  }
                  onClick={() => toogleFullScreen("orderDetail")}
                />
              </ViewReverse>
            </ViewReverse>

            {isDetailOpen.orderDetail && (
              <>
                <ConfigProvider
                  tabs={{
                    style: {
                      color: "red",
                    },
                  }}
                  theme={{
                    components: {
                      Tabs: {
                        cardBg: "white",
                        // colorBorderSecondary: 'transparent',
                      },
                    },
                  }}
                >
                  <Tabs
                    defaultActiveKey="1"
                    type="card"
                    className="w-full"
                    items={orderDetailsTabs(getContentKey)}
                  />
                </ConfigProvider>

                <ConfigProvider
                  theme={{
                    components: {
                      Table: {
                        headerBg: "#E0E0E0",
                        headerSplitColor: "transparent",
                        borderRadius: 0,
                        padding: 15,
                      },
                    },
                  }}
                >
                  <Table
                    columns={isArabic ? orderDetailColumns(TextWeight, getContentKey)?.reverse() : orderDetailColumns(TextWeight, getContentKey)}
                    dataSource={orderDetailData}
                    pagination={false}
                    className="rounded-tr-[10px] rounded-tl-[10px] border-t-grey border overflow-hidden"
                    scroll={{
                      x: "max-content",
                      y: 250,
                    }}
                    locale={{ emptyText: "No data available in table" }}
                    onRow={(i) => ({
                      // onClick: (e) => {
                      // },
                    })}
                  />
                </ConfigProvider>
              </>
            )}
          </ViewItems>

          <ViewItems
            className={"border border-grey mt-6 rounded px-5 py-3 gap-2 w-full"}
          >
            <ViewReverse className={"w-full justify-between"}>
              <Text
                text={getContentKey("order_basket")}
                color="text-primary-light"
                size={fontSizes.h14}
              />
              <ViewReverse className={"gap-2 items-center"}>
                <div className="w-4 h-4 bg-grey rounded-full" />
                <ReactSVG src={icons.filter} />
                <ReactSVG src={icons.roundArrow} className="opacity-25" />
                <ReactSVG
                  className="cursor-pointer"
                  src={
                    isDetailOpen.orderBasket
                      ? icons.smallScreen
                      : icons.enterFullScreen
                  }
                  onClick={() => toogleFullScreen("orderBasket")}
                />
              </ViewReverse>
            </ViewReverse>

            {isDetailOpen.orderBasket && (
              <>
                <ConfigProvider
                  tabs={{
                    style: {
                      color: "red",
                    },
                  }}
                  theme={{
                    components: {
                      Tabs: {
                        cardBg: "white",
                        // colorBorderSecondary: 'transparent',
                      },
                    },
                  }}
                >
                  <Tabs
                    defaultActiveKey="1"
                    type="card"
                    className="w-full"
                    items={new Array(3).fill(null).map((_, i) => {
                      const id = String(i + 1);
                      return {
                        label: `Card Tab ${id}`,
                        key: id,
                        // children: `Content of card tab ${id}`,
                      };
                    })}
                    tabBarExtraContent={
                      <ViewReverse className={"gap-2 mb-1"}>
                        <Button
                          text={getContentKey("submit")}
                          color={"text-dark-grey"}
                          bgColor="bg-white"
                          className={
                            "border border-dark-grey w-32 px-0 text-center text-dark-grey text-opacity-30 border-opacity-30"
                          }
                          icon={icons.tick}
                          iconClassName="opacity-30 mb-1"
                        />

                        <Button
                          text={getContentKey("simulate")}
                          color={"text-dark-grey"}
                          bgColor="bg-white"
                          className={
                            "border border-dark-grey w-32 px-0 text-center text-dark-grey text-opacity-30 border-opacity-30"
                          }
                          icon={icons.play}
                          iconClassName="opacity-30 mb-1"
                        />

                        <Button
                          text={getContentKey("delete")}
                          color={"text-dark-grey"}
                          bgColor="bg-white"
                          className={
                            "border border-dark-grey w-32 px-0 text-center text-dark-grey text-opacity-30 border-opacity-30"
                          }
                          icon={icons.delete_icon}
                          iconClassName="opacity-30 mb-1"
                        />
                      </ViewReverse>
                    }
                  />
                </ConfigProvider>

                <ConfigProvider
                  theme={{
                    components: {
                      Table: {
                        headerBg: "#E0E0E0",
                        headerSplitColor: "transparent",
                        borderRadius: 0,
                        padding: 15,
                      },
                    },
                  }}
                >
                  <Table
                    columns={isArabic ? orderBasketColumns(TextWeight, getContentKey)?.reverse() : orderBasketColumns(TextWeight, getContentKey)}
                    dataSource={orderBasketData}
                    pagination={false}
                    className="rounded-tr-[10px] rounded-tl-[10px] border-t-grey border overflow-hidden"
                    scroll={{
                      x: "max-content",
                      y: 250,
                    }}
                    rowSelection={{
                      type: "checkbox",
                    }}
                    locale={{ emptyText: "No data available in table" }}
                    onRow={(i) => ({
                      // onClick: (e) => {
                      // },
                    })}
                  />
                </ConfigProvider>
              </>
            )}
          </ViewItems>
        </>
      )}
    </div>
  );
};

export default OrderList;
