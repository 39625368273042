import React, { useState } from "react";
import ViewReverse from "../../../components/ViewReverse";
import Text from "../../../components/Text";
import { fontSizes } from "../../../constants/constants";
import icons from "../../../constants/icons";
import { ReactSVG } from "react-svg";
import CustomDropdown from "../../../components/CustomDropdown";
import { dropdownOptions } from "../../../config/config";
import CustomInput from "../../../components/CustomInput";
import ViewItems from "../../../components/ViewItems";
import CustomDatePicker from "../../../components/CustomDatePicker";
import { ConfigProvider, Tabs } from "antd";
import Button from "../../../components/Button";
import { getContentKeyAccToLang } from "../../../utils/utils";
import dynamicfunction from "../../../utils/dynamicfunction";
import { useSelector } from "react-redux";

const EditOrder = () => {
  const { tradingContent } = useSelector((state) => state.content);
  const { isArabic } = dynamicfunction();

  const [orderDetails, setOrderDetails] = useState({
    side: "",
    portfolio: "",
    type: "",
    goodTill: "",
    cashAccount: "",
    fillTerm: "",
    priceType: "",
    priceDirection: "",
  });

  const handleUpdateDetials = (key, value) =>
    setOrderDetails((prev) => ({
      ...prev,
      [key]: value,
    }));

    const getContentKey = (key) => {
      return getContentKeyAccToLang(
        tradingContent?.web_trading_order?.data?.attributes,
        key,
        isArabic
      );
    };

  return (
    <div className="w-full border border-grey p-4 rounded mt-4">
      <ViewReverse className={"w-full items-center justify-between"}>
        <Text
          text={getContentKey("detailed_trade")}
          color="text-primary-light"
          size={fontSizes.h14}
        />
        <ViewReverse className={"gap-2 items-center"}>
          <ReactSVG src={icons.modalCross} className="opacity-20" />
          <ReactSVG src={icons.settings} className="opacity-40" />
          <ReactSVG src={icons.roundArrow} className="opacity-20" />
          <ReactSVG
            className="cursor-pointer"
            src={icons.smallScreen}
            //   onClick={() => toogleFullScreen("watchList")}
          />
        </ViewReverse>
      </ViewReverse>

      <ViewReverse className={"gap-4"}>
        <CustomDropdown
          label={getContentKey("side")}
          selectedValue={orderDetails.side}
          onSelect={(value) => handleUpdateDetials("side", value)}
          options={dropdownOptions}
          isBorder
        />
        <CustomDropdown
          label={getContentKey("portfolio")}
          selectedValue={orderDetails.portfolio}
          onSelect={(value) => handleUpdateDetials("portfolio", value)}
          options={dropdownOptions}
          isBorder
        />
        <CustomInput
          label={getContentKey("add_symbol")}
          placeholder={"1020 Al-Jazira Bank"}
          isDisabled
          isFullborder
          isRounded
        />
        <CustomDropdown
          label={getContentKey("type")}
          selectedValue={orderDetails.type}
          onSelect={(value) => handleUpdateDetials("type", value)}
          options={dropdownOptions}
          isBorder
        />
      </ViewReverse>

      <ViewReverse className={"gap-4 mt-4 mb-6"}>
        <ViewItems className={"w-full"}>
          <Text
            text={getContentKey("quantity")}
            size={fontSizes.h14}
            color={"text-primary-light"}
            className={"mb-1"}
          />
          <ViewReverse
            className={
              "w-full rounded items-center border border-grey p-2 h-full"
            }
          >
            <input className="outline-none w-full" />
            <ViewItems className={"gap-1"}>
              <ReactSVG src={icons.plus} />
              <ReactSVG src={icons.minus} />
            </ViewItems>
          </ViewReverse>
        </ViewItems>

        <ViewItems className={"w-full"}>
          <Text
            text={getContentKey("price")}
            size={fontSizes.h14}
            color={"text-primary-light"}
            className={"mb-1"}
          />
          <ViewReverse
            className={
              "w-full rounded justify-between items-center border border-grey p-2 h-full"
            }
          >
            <ReactSVG src={icons.minus} />
            <Text text="16.50" color="text-grey" size={fontSizes.h14} />
            <ReactSVG src={icons.plus} />
          </ViewReverse>
        </ViewItems>

        <CustomDropdown
          label={getContentKey("good_till")}
          selectedValue={orderDetails.goodTill}
          onSelect={(value) => handleUpdateDetials("goodTill", value)}
          options={dropdownOptions}
          isBorder
        />

        <CustomDatePicker label={getContentKey("expiry_date")} />
      </ViewReverse>

      <ConfigProvider
        tabs={{
          style: {
            color: "red",
          },
        }}
        theme={{
          components: {
            Tabs: {
              cardBg: "white",
              // colorBorderSecondary: 'transparent',
            },
          },
        }}
      >
        <Tabs
          defaultActiveKey="1"
          type="card"
          className="w-full"
          items={[{ key: "details", label: getContentKey("details") }]}
        />
      </ConfigProvider>

      <ViewReverse
        className={
          "bg-grey p-2 rounded bg-opacity-20 justify-around items-center"
        }
      >
        <ViewReverse className={"gap-6"}>
          <div className="flex flex-col items-center justify-center">
            <Text text={getContentKey("available_share")} size={fontSizes.h14} />
            <Text text="0" size={fontSizes.h14} />
          </div>
          <div className="flex flex-col items-center justify-center">
            <Text text={getContentKey("purchase_power")} size={fontSizes.h14} />
            <Text
              text="8,448,562.87"
              color="text-light-blue"
              size={fontSizes.h14}
            />
          </div>
        </ViewReverse>

        <div className="h-10 w-[2px] bg-grey" />

        <ViewReverse className={"gap-6"}>
          <div className="flex flex-col items-center justify-center">
            <Text text={getContentKey("best_bid")} size={fontSizes.h14} />
            <Text text="0,00 (0)" size={fontSizes.h14} />
          </div>
          <div className="flex flex-col items-center justify-center">
            <Text text={getContentKey("best_offer")} size={fontSizes.h14} />
            <Text
              text="0,00 (0)"
              color="text-light-blue"
              size={fontSizes.h14}
            />
          </div>
        </ViewReverse>

        <div className="h-10 w-[2px] bg-grey" />

        <ViewReverse className={"gap-6"}>
          <div className="flex flex-col items-center justify-center">
            <Text text={getContentKey("last")} size={fontSizes.h14} />
            <Text text="0,00 (0)" size={fontSizes.h14} />
          </div>
          <div className="flex flex-col items-center justify-center">
            <Text text={getContentKey("t_high")} size={fontSizes.h14} />
            <Text
              text="0,00 (0)"
              color="text-light-blue"
              size={fontSizes.h14}
            />
          </div>
          <div className="flex flex-col items-center justify-center">
            <Text text={getContentKey("t_low")} size={fontSizes.h14} />
            <Text
              text="0,00 (0)"
              color="text-light-blue"
              size={fontSizes.h14}
            />
          </div>
        </ViewReverse>
      </ViewReverse>

      <ViewReverse className={"gap-4 mt-4"}>
        <CustomDropdown
          label={getContentKey("cash_account")}
          selectedValue={orderDetails.cashAccount}
          onSelect={(value) => handleUpdateDetials("cashAccount", value)}
          options={dropdownOptions}
          isBorder
        />
        <CustomDropdown
          label={getContentKey("fill_term")}
          selectedValue={orderDetails.fillTerm}
          onSelect={(value) => handleUpdateDetials("fillTerm", value)}
          options={dropdownOptions}
          isBorder
        />
        <ViewItems className={"w-full"}>
          <Text
            text={getContentKey("min_qty")}
            size={fontSizes.h14}
            color={"text-primary-light"}
            className={"mb-1"}
          />
          <ViewReverse
            className={
              "w-full rounded items-center border border-grey p-2 h-full"
            }
          >
            <input className="outline-none w-full" />
            <ViewItems className={"gap-1"}>
              <ReactSVG src={icons.plus} />
              <ReactSVG src={icons.minus} />
            </ViewItems>
          </ViewReverse>
        </ViewItems>

        <CustomInput
          label={getContentKey("disc_volume")}
          placeholder={"0"}
          isDisabled
          isFullborder
          isRounded
        />
      </ViewReverse>

      <ViewReverse className={"gap-4 mt-4"}>
        <ViewItems className={"w-full"}>
          <Text
            text={getContentKey("trigger_price")}
            size={fontSizes.h14}
            color={"text-primary-light"}
            className={"mb-1"}
          />
          <ViewReverse
            className={
              "w-full rounded justify-between items-center border border-grey p-2 h-full"
            }
          >
            <ReactSVG src={icons.minus} />
            <Text text="16.50" color="text-grey" size={fontSizes.h14} />
            <ReactSVG src={icons.plus} />
          </ViewReverse>
        </ViewItems>

        <CustomDropdown
          label={getContentKey("trigger_price_type")}
          selectedValue={orderDetails.priceType}
          onSelect={(value) => handleUpdateDetials("priceType", value)}
          options={dropdownOptions}
          isBorder
        />

        <CustomDropdown
          label={getContentKey("trigger_price_direction")}
          selectedValue={orderDetails.priceDirection}
          onSelect={(value) => handleUpdateDetials("priceDirection", value)}
          options={dropdownOptions}
          isBorder
        />

        <div className="w-full" />
      </ViewReverse>

      <ViewReverse
        className={
          "bg-grey bg-opacity-20 justify-between gap-4 rounded px-4 py-2 mt-8 flex-wrap"
        }
      >
        <ViewReverse className={"gap-4"}>
          <ViewReverse className={"items-center gap-4"}>
            <Text
              text={getContentKey("trade_amt")}
              color="text-light-blue"
              size={fontSizes.h14}
            />
            <div className="bg-grey px-3 py-2 rounded bg-opacity-60">
              <Text
                text="198.00"
                color="text-light-blue"
                size={fontSizes.h14}
              />
            </div>
          </ViewReverse>

          <ViewReverse className={"items-center gap-4"}>
            <Text text="Fees" color="text-light-blue" size={fontSizes.h14} />
            <div className="bg-grey px-3 py-2 rounded bg-opacity-60">
              <Text text="0.20" color="text-light-blue" size={fontSizes.h14} />
            </div>
          </ViewReverse>
        </ViewReverse>

        <ViewReverse className={"gap-2 items-center"}>
          <Text text={getContentKey("order_amt")} size={fontSizes.h14} />
          <div className="bg-white border border-grey rounded px-2 py-1 flex justify-center items-center">
            <Text text="198.20" size={fontSizes.h14} className={"opacity-50"} />
          </div>
          <Text text="SAR" size={fontSizes.h14} color="text-light-blue" />
        </ViewReverse>

        <ViewReverse className={"gap-4"}>
          <div className="bg-dark-blue rounded flex items-center justify-center px-2 gap-2">
            <ReactSVG src={icons.calculator} />
            <Text
              text={getContentKey("calculate_total")}
              color="text-white"
              className={"mt-1"}
            />
          </div>
          <Button
            text={getContentKey("clear")}
            bgColor="bg-white"
            color={"text-dark-grey"}
            className={
              "w-28 text-center border border-dark-grey border-opacity-50"
            }
          />
          <Button
            text={getContentKey("place_order")}
            bgColor="bg-orange"
            className={"w-28 text-center border border-orange"}
          />
        </ViewReverse>
      </ViewReverse>
    </div>
  );
};

export default EditOrder;
