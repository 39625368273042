import React, { useEffect, useState } from "react";
import ViewItems from "../../../components/ViewItems";
import Text from "../../../components/Text";
import dynamicfunction from "../../../utils/dynamicfunction";
import { fontSizes } from "../../../constants/constants";
import CustomDropdown from "../../../components/CustomDropdown";
import { languageDropdown } from "../../../config/config";
import ViewReverse from "../../../components/ViewReverse";
import Button from "../../../components/Button";
import Divider from "../../../components/Divider";
import { ReactSVG } from "react-svg";
import icons from "../../../constants/icons";
import CustomInput from "../../../components/CustomInput";
import { useDispatch, useSelector } from "react-redux";
import { getContentKeyAccToLang } from "../../../utils/utils";
import {
  changeLanguage,
  changePassword,
} from "../../../store/actions/settingAction";
import FullScreenLoader from "../../../components/FullScreenLoader";
import { rowReverseStyle, rowReverseStylemd } from "../../../utils/styles";

const GeneralSettings = () => {
  const { TextWeight, isArabic } = dynamicfunction();
  const { settingContent } = useSelector((state) => state.content);
  const { settingLoading } = useSelector((state) => state.setting);
  const { user } = useSelector((state) => state.auth);
  const dispatch = useDispatch();

  useEffect(() => {
    console.log('user, user', user)
  }, [])

  const getContentKey = (key) => {
    return getContentKeyAccToLang(
      settingContent?.web_general_setting?.data?.attributes,
      key,
      isArabic
    );
  };

  const [selectedLanguage, setSelectedLanguage] = useState("select");
  const [passwordData, setPasswordData] = useState({
    oldPassword: "",
    newPassword: "",
    confirmPassword: "",
  });

  const onLanguageSelect = (val) => setSelectedLanguage(val);

  const handleSubmitLanguage = () => {
    if (selectedLanguage !== "select") {
      dispatch(changeLanguage({languageId: selectedLanguage, clientCode: user?.clientCode}));
    }
  };

  const handlePasswordChange = (key, val) => {
    setPasswordData((prev) => ({ ...prev, [key]: val }));
  };

  const handleSubmitPassword = () => {
    dispatch(changePassword(passwordData));
  };

  return (
    <ViewItems>
      {settingLoading && <FullScreenLoader />}
      <Text
        text={getContentKey("heading")}
        weight={TextWeight.headingRegular}
        size={fontSizes.h24}
        color={"text-dark-blue"}
      />
      <Text
        text={getContentKey("language")}
        size={fontSizes.h20}
        color={"text-light-blue"}
        className={"mt-2"}
      />

      <div className="mt-4 w-full md:w-1/2">
        <CustomDropdown
          label={getContentKey("lang_label")}
          options={languageDropdown(getContentKey)}
          selectedValue={selectedLanguage}
          onSelect={onLanguageSelect}
        />
      </div>

      <ViewReverse className="items-center w-full justify-end gap-2 mt-8 mb-6">
        <Button
          text={getContentKey('save_btn')}
          onClick={handleSubmitLanguage}
          bgColor="bg-orange"
          className={"uppercase w-32 px-0 text-center border border-orange"}
        />
        <Button
          text={getContentKey('cancel_btn')}
          color={"text-dark-grey"}
          bgColor="bg-white"
          className={
            "uppercase border border-dark-grey w-32 px-0 text-center border-opacity-50"
          }
        />
      </ViewReverse>

      <Divider />

      <Text
        text={getContentKey("kyc_heading")}
        size={fontSizes.h20}
        color={"text-light-blue"}
        className={"mt-4"}
      />

      <ViewReverse className={"flex-wrap mt-4 gap-1 items-center"}>
        <Text
          text={getContentKey("kyc_msg")}
          weight={TextWeight.light}
          size={fontSizes.h14}
          color={"text-dark-grey"}
          className={'inline-block'}
        />
        <Text
          text={getContentKey("click_here")}
          size={fontSizes.h14}
          color={"text-light-blue"}
          className={'inline-block'}
        />
        <ReactSVG src={icons.openLink} className="mb-1 inline-block" />
      </ViewReverse>

      <Text
        text={getContentKey("new_window")}
        size={fontSizes.h14}
        color={"text-grey"}
        className={"mt-2"}
      />

      <Divider />

      <Text
        text={getContentKey("change_pass")}
        size={fontSizes.h20}
        color={"text-light-blue"}
        className={"my-4"}
      />

      <ViewReverse className={`flex w-full flex-col ${rowReverseStylemd(isArabic)}`}>
        <div className="w-full md:w-1/2">
          <CustomInput
            label={getContentKey("old_pass_label")}
            placeholder={getContentKey("old_pass_placeholder")}
            type={"password"}
            isRounded
            value={passwordData.oldPassword}
            onChange={(val) =>
              handlePasswordChange("oldPassword", val.target.value)
            }
          />
          <CustomInput
            label={getContentKey("new_pass_label")}
            placeholder={getContentKey("new_pass_placeholder")}
            type={"password"}
            isRounded
            divClasses={"my-4"}
            isRequired
            value={passwordData.newPassword}
            onChange={(val) =>
              handlePasswordChange("newPassword", val.target.value)
            }
          />
          <CustomInput
            label={getContentKey("confirm_pass_label")}
            placeholder={getContentKey("new_pass_placeholder")}
            type={"password"}
            isRounded
            value={passwordData.confirmPassword}
            onChange={(val) =>
              handlePasswordChange("confirmPassword", val.target.value)
            }
          />
        </div>
        <div
          className={`w-full md:w-1/2 mt-4 md:mt-0 ${isArabic ? "md:pe-10" : "md:ps-10"}`}
        >
          <Text
            text={getContentKey("pass_validation_heading")}
            color={"text-red-500"}
            size={fontSizes.h14}
          />
          <ul className={"pl-4"}>
            <li className="text-red-500 text-[14px]">
              <Text
                text={getContentKey("pass_validation_1")}
                color={"text-red-500"}
                size={fontSizes.h14}
                weight={TextWeight.light}
              />
            </li>
            <li className="text-red-500 text-[14px]">
              <Text
                text={getContentKey("pass_validation_2")}
                color={"text-red-500"}
                size={fontSizes.h14}
                weight={TextWeight.light}
              />
            </li>
            <li className="text-red-500 text-[14px]">
              <Text
                text={getContentKey("pass_validation_3")}
                color={"text-red-500"}
                size={fontSizes.h14}
                weight={TextWeight.light}
              />
            </li>
            <li className="text-red-500 text-[14px]">
              <Text
                text={getContentKey("pass_validation_4")}
                size={fontSizes.h14}
                color={"text-red-500"}
                weight={TextWeight.light}
              />
            </li>
          </ul>
        </div>
      </ViewReverse>

      <ViewReverse className="items-center w-full justify-end gap-2 mt-8 mb-6">
        <Button
          text={getContentKey('save_btn')}
          bgColor="bg-orange"
          className={"uppercase w-32 px-0 text-center border border-orange"}
          onClick={handleSubmitPassword}
        />
        <Button
          text={getContentKey('cancel_btn')}
          color={"text-dark-grey"}
          bgColor="bg-white"
          className={
            "uppercase border border-dark-grey w-32 px-0 text-center border-opacity-50"
          }
        />
      </ViewReverse>
    </ViewItems>
  );
};

export default GeneralSettings;
