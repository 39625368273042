import React from 'react'
import Text from '../../../components/Text';
import { fontSizes } from '../../../constants/constants';
import dynamicfunction from '../../../utils/dynamicfunction';
import images from '../../../constants/images';
import { getContentKeyAccToLang } from '../../../utils/utils';
import { useSelector } from 'react-redux';

const Tadawulaty = () => {
  const { tradingContent } = useSelector((state) => state.content);
  const { TextWeight, isArabic } = dynamicfunction();

  const getContentKey = (key) => {
    return getContentKeyAccToLang(
      tradingContent?.web_trading_tadawulaty?.data?.attributes,
      key,
      isArabic
    );
  };

  return (
    <div>
      <Text
          text={getContentKey("tadawulaty")}
          weight={TextWeight.headingBold}
          size={fontSizes.h24}
          color="text-dark-blue"
        />

        <div className={"flex items-center justify-center h-96 border border-grey mt-6 rounded px-5 py-3 gap-2"}>
          <img src={images.tadawulaty} className={"h-44"} />
        </div>
    </div>
  )
}

export default Tadawulaty;