import React, { useState } from "react";
import Text from "../../../components/Text";
import dynamicfunction from "../../../utils/dynamicfunction";
import { fontSizes } from "../../../constants/constants";
import ViewReverse from "../../../components/ViewReverse";
import ViewItems from "../../../components/ViewItems";
import CustomDropdown from "../../../components/CustomDropdown";
import { dropdownOptions } from "../../../config/config";
import CustomInput from "../../../components/CustomInput";
import CustomTextArea from "../../../components/CustomTextArea";
import Button from "../../../components/Button";

const Contact = () => {
  const { TextWeight } = dynamicfunction();

  const [contactDetails, setContactDetails] = useState({
    to: "",
  });

  const handleValueChnage = (key) =>
    setContactDetails((prev) => ({
      ...prev,
      [key]: !prev[key],
    }));

  return (
    <div>
      <Text
        text={"Contact"}
        weight={TextWeight.headingBold}
        size={fontSizes.h24}
        color={"text-dark-blue"}
      />

      <ViewReverse className={"w-full mt-6 gap-10"}>
        <ViewItems className={"w-1/2 gap-6"}>
          <CustomDropdown
            label={"To"}
            options={dropdownOptions}
            onSelect={(value) => handleValueChnage("to", value)}
            selectedValue={contactDetails.to}
            isBorder
          />
          <CustomInput
            label={"Subject"}
            placeholder={"Enter subject"}
            isFullborder
            isRounded
          />
          <CustomTextArea
            label={"Description"}
            isFullborder
            isRounded
            rows={10}
          />
          <ViewReverse className={"w-full justify-end"}>
            <Button
              text={"PLACE ORDER"}
              bgColor="bg-orange"
              className={"w-28 text-center border border-orange"}
            />
          </ViewReverse>
        </ViewItems>
        <ViewItems className={"w-1/2"}>
          <ViewItems className={"w-3/4 bg-grey p-3 rounded bg-opacity-30"}>
            <Text
              text="Relationship manager"
              color="text-primary-light"
              size={fontSizes.h14}
            />
            <ViewReverse className={"w-full mt-4"}>
              <ViewItems className={"w-1/2 gap-2"}>
                <Text text="Name" isColon />
                <Text text="E-mail" isColon />
                <Text text="Landline" isColon />
                <Text text="Mobile" isColon />
              </ViewItems>
              <ViewItems className={"w-1/2 gap-2"}>
                <Text text="Hamza Al-Ghannam" isColon />
                <Text text="halghannam@jadwa.com" isColon />
                <Text text="+966 11 279 1111 Ex. 4894" isColon />
                <Text text="+966 50 255 7766" isColon />
              </ViewItems>
            </ViewReverse>
          </ViewItems>
        </ViewItems>
      </ViewReverse>
    </div>
  );
};

export default Contact;
