import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  marketSummary: null,
  watchList: null,
  portfolioSummary: null,
  portfolioPostion: null,
  orderBasketList: null,
};

const tradingSlice = createSlice({
  name: "trading",
  initialState,
  reducers: {
    setMarketSummary: (state, action) => {
      state.marketSummary = action.payload;
    },
    setPortfolioSummary: (state, action) => {
      state.portfolioSummary = action.payload;
    },
    setWatchList: (state, action) => {
      state.watchList = action.payload;
    },
    setPortfolioPosition: (state, action) => {
      state.portfolioPostion = action.payload;
    },
    setOrderBasketList: (state, action) => {
      state.orderBasketList = action.payload;
    },
  },
});

export const {
  setMarketSummary,
  setPortfolioSummary,
  setWatchList,
  setPortfolioPosition,
  setOrderBasketList,
} = tradingSlice.actions;
export default tradingSlice.reducer;
