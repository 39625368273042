import React, { useState } from "react";
import ViewReverse from "../../../components/ViewReverse";
import Text from "../../../components/Text";
import dynamicfunction from "../../../utils/dynamicfunction";
import { fontSizes } from "../../../constants/constants";
import Button from "../../../components/Button";
import { ReactSVG } from "react-svg";
import icons from "../../../constants/icons";
import ViewItems from "../../../components/ViewItems";
import { ConfigProvider, Table, Tabs } from "antd";
import {
  activeSymbolsTabs,
  chartDurationLabel,
  gainLossColumns,
  gainLossData,
  gainLossTabs,
  indicesData,
} from "../../../config/config";
import {
  Chart,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Filler,
} from "chart.js";
import { Line } from "react-chartjs-2";
import BuyModal from "../../../components/BuyModal";
import { rowReverseStylesm } from "../../../utils/styles";
import { getContentKeyAccToLang } from "../../../utils/utils";
import { useSelector } from "react-redux";

Chart.register(CategoryScale, LinearScale, PointElement, LineElement, Filler);

const chartData = {
  labels: ["31/12", "31/12", "03/01", "04/01", "05/01", "05/01", "07/01"],
  datasets: [
    {
      label: "Value",
      data: [6190, 6180, 6180, 6170, 6170, 6180, 6180],
      backgroundColor: "#00205A",
      // borderColor: "#00205A",
      // borderWidth: 1,
      pointRadius: 0,
      fill: true,
      // stepped: true,
    },
  ],
};

const chartOptions = {
  scales: {
    y: {
      // min: 5800,
      // max: 6500,
      ticks: {
        stepSize: 50,
      },
      grid: {
        display: false,
      },
    },
    x: {
      ticks: {
        autoSkip: false,
      },
    },
  },
  legend: {
    display: false,
  },
  title: {
    display: false,
    text: "Stepped Area Chart Example",
  },
};

const Market = () => {
  const { tradingContent } = useSelector((state) => state.content);
  const { TextWeight, isArabic } = dynamicfunction();

  const [selectedDuration, setSelectedDuration] = useState("1d");
  const [isDetailOpen, setIsDetailOpen] = useState({
    marketOverview: true,
    tadawulChart: true,
    orderDetailed: true,
    activeSymbolsTable: true,
    activeSymbolsList: true,
    mostActiveList: true,
    news: true,
  });
  const [isBuyModal, setIsBuyModal] = useState(false);

  const toogleFullScreen = (key) =>
    setIsDetailOpen((prev) => ({
      ...prev,
      [key]: !prev[key],
    }));

  const getContentKey = (key) => {
    return getContentKeyAccToLang(
      tradingContent?.web_trading_market?.data?.attributes,
      key,
      isArabic
    );
  };

  return (
    <div>
      <ViewReverse className={"justify-between mb-2"}>
        <Text
          text={getContentKey("market")}
          weight={TextWeight.headingBold}
          size={fontSizes.h24}
          color="text-dark-blue"
        />

        <ViewReverse className={"gap-3 relative"}>
          <ViewReverse className={"items-center gap-2 me-3"}>
            <div className="h-4 w-4 rounded-full bg-green-500" />
            <Text
              text={getContentKey("connected")}
              className={"uppercase opacity-50"}
              size={fontSizes.h10}
              weight={TextWeight.bold}
              color="text-dark-grey"
            />
          </ViewReverse>
          <Button
            text={getContentKey("buy")}
            bgColor="bg-light-blue"
            weight={TextWeight.extraBold}
            className={"px-5"}
            onClick={() => setIsBuyModal(!isBuyModal)}
          />
          <BuyModal
            isOpen={isBuyModal}
            onClose={() => setIsBuyModal(!isBuyModal)}
          />
          <Button
            text={getContentKey("sell")}
            bgColor="bg-dark-blue"
            weight={TextWeight.extraBold}
            className={"px-5"}
          />
        </ViewReverse>
      </ViewReverse>

      <ViewReverse
        className={`w-full gap-4 flex-col ${rowReverseStylesm(isArabic)}`}
      >
        <div className="w-full sm:w-1/2 border border-grey rounded px-5 py-3 pb-10">
          <ViewReverse className={"w-full items-center justify-between mb-3"}>
            <Text
              text={getContentKey("tadawul_summary")}
              color="text-primary-light"
              size={fontSizes.h14}
            />
            <ViewReverse className={"gap-2 items-center"}>
              <div className="w-4 h-4 bg-amber-500 rounded-full" />
              <ReactSVG src={icons.filter} />
              <ReactSVG
                className="cursor-pointer"
                src={
                  isDetailOpen.marketOverview
                    ? icons.smallScreen
                    : icons.enterFullScreen
                }
                onClick={() => toogleFullScreen("marketOverview")}
              />
            </ViewReverse>
          </ViewReverse>

          {isDetailOpen.marketOverview && (
            <>
              <ViewReverse
                className={
                  "w-full bg-grey bg-opacity-30 rounded p-4 items-center justify-center gap-6"
                }
              >
                <Text
                  text="7,526.54"
                  color="text-dark-blue"
                  size={fontSizes.h16}
                  weight={TextWeight.bold}
                />

                <ReactSVG src={icons.lossArrow} />

                <div className={"flex flex-col items-center"}>
                  <Text
                    text={getContentKey("close")}
                    color="text-red-500"
                    weight={TextWeight.light}
                  />
                  <Text
                    text="-135.63(-1.77%)"
                    color="text-red-500"
                    weight={TextWeight.bold}
                    size={fontSizes.h16}
                  />
                </div>
              </ViewReverse>

              <ViewReverse className={"w-full py-10"}>
                <div className="w-1/2 flex flex-col items-center">
                  <Text
                    text={getContentKey("turnover")}
                    color="text-light-blue"
                    weight={TextWeight.light}
                    size={fontSizes.h14}
                  />
                  <Text
                    text="2,683,331,854,480"
                    color="text-dark-blue"
                    weight={TextWeight.light}
                    size={fontSizes.h14}
                  />
                </div>
                <div className="w-1/2 flex flex-col items-center">
                  <Text
                    text={getContentKey("t_volume")}
                    color="text-light-blue"
                    weight={TextWeight.light}
                    size={fontSizes.h14}
                  />
                  <Text
                    text="130,046,146"
                    color="text-dark-blue"
                    weight={TextWeight.light}
                    size={fontSizes.h14}
                  />
                </div>
              </ViewReverse>

              <ViewReverse className={"justify-around items-center"}>
                <div className="flex flex-col items-center">
                  <Text
                    text={getContentKey("trades")}
                    color="text-light-blue"
                    weight={TextWeight.light}
                    size={fontSizes.h14}
                  />
                  <Text
                    text="101,969"
                    color="text-dark-blue"
                    weight={TextWeight.light}
                    size={fontSizes.h14}
                  />
                </div>
                <div className="w-[2px] h-20 bg-grey" />
                <div className="flex flex-col items-center">
                  <Text
                    text={getContentKey("traded")}
                    color="text-light-blue"
                    weight={TextWeight.light}
                    size={fontSizes.h14}
                  />
                  <Text
                    text="186"
                    color="text-dark-blue"
                    weight={TextWeight.light}
                    size={fontSizes.h14}
                  />
                </div>
                <div className="w-[2px] h-20 bg-grey" />
                <div className="flex flex-col items-center">
                  <Text
                    text={getContentKey("up")}
                    color="text-light-blue"
                    weight={TextWeight.light}
                    size={fontSizes.h14}
                  />
                  <Text
                    text="17"
                    color="text-dark-blue"
                    weight={TextWeight.light}
                    size={fontSizes.h14}
                  />
                </div>
                <div className="w-[2px] h-20 bg-grey" />
                <div className="flex flex-col items-center">
                  <Text
                    text={getContentKey("down")}
                    color="text-light-blue"
                    weight={TextWeight.light}
                    size={fontSizes.h14}
                  />
                  <Text
                    text="164"
                    color="text-dark-blue"
                    weight={TextWeight.light}
                    size={fontSizes.h14}
                  />
                </div>
              </ViewReverse>
            </>
          )}
        </div>

        <div className="w-full sm:w-1/2 border border-grey rounded px-5 py-3 flex flex-col justify-between">
          <ViewItems>
            <ViewReverse className={"w-full items-center justify-between mb-3"}>
              <Text
                text={getContentKey("tadawul_chart")}
                color="text-primary-light"
                size={fontSizes.h14}
              />
              <ViewReverse className={"gap-2 items-center"}>
                <div className="w-4 h-4 bg-amber-500 rounded-full" />
                <ReactSVG src={icons.filter} />
                <ReactSVG
                  className="cursor-pointer"
                  src={
                    isDetailOpen.tadawulChart
                      ? icons.smallScreen
                      : icons.enterFullScreen
                  }
                  onClick={() => toogleFullScreen("tadawul_chart")}
                />
              </ViewReverse>
            </ViewReverse>

            {isDetailOpen.tadawulChart && (
              <ViewReverse>
                {chartDurationLabel.map((item) => (
                  <div
                    className={`py-[2px] px-3 mx-1 bg-grey cursor-pointer ${
                      selectedDuration === item.key
                        ? "bg-opacity-100"
                        : "bg-opacity-50"
                    }`}
                    onClick={() => setSelectedDuration(item.key)}
                  >
                    <Text
                      text={item.label}
                      size={fontSizes.h14}
                      className={"mt-[3px]"}
                    />
                  </div>
                ))}
              </ViewReverse>
            )}
          </ViewItems>

          {isDetailOpen.tadawulChart && (
            <div className="w-full mt-4">
              <Line data={chartData} options={chartOptions} />
            </div>
          )}
        </div>
      </ViewReverse>

      <ViewItems className={"border border-grey mt-6 rounded px-5 py-3 gap-2"}>
        <ViewReverse className={"w-full justify-between"}>
          <Text
            text={getContentKey("orders_detail")}
            color="text-primary-light"
            size={fontSizes.h14}
          />
          <ViewReverse className={"gap-2 items-center"}>
            <div className="w-4 h-4 bg-grey rounded-full" />
            <ReactSVG src={icons.filter} />
            <ReactSVG src={icons.roundArrow} className="opacity-25" />
            <ReactSVG
              className="cursor-pointer"
              src={
                isDetailOpen.orderDetailed
                  ? icons.smallScreen
                  : icons.enterFullScreen
              }
              onClick={() => toogleFullScreen("orderDetailed")}
            />
          </ViewReverse>
        </ViewReverse>

        {isDetailOpen.orderDetailed && (
          <>
            <ConfigProvider
              tabs={{
                style: {
                  color: "red",
                },
              }}
              theme={{
                components: {
                  Tabs: {
                    cardBg: "white",
                    // colorBorderSecondary: 'transparent',
                  },
                },
              }}
            >
              <Tabs
                defaultActiveKey="1"
                type="card"
                className="w-full"
                items={gainLossTabs(getContentKey)}
              />
            </ConfigProvider>

            <ConfigProvider
              theme={{
                components: {
                  Table: {
                    headerBg: "#E0E0E0",
                    headerSplitColor: "transparent",
                    borderRadius: 0,
                    padding: 15,
                  },
                },
              }}
            >
              <Table
                columns={
                  isArabic
                    ? gainLossColumns(TextWeight, getContentKey)?.reverse()
                    : gainLossColumns(TextWeight, getContentKey)
                }
                dataSource={gainLossData}
                pagination={false}
                className="rounded-tr-[10px] rounded-tl-[10px] border-t-grey border overflow-hidden"
                scroll={{
                  x: "max-content",
                  y: 250,
                }}
                locale={{ emptyText: "No data available in table" }}
                onRow={(i) => ({
                  // onClick: (e) => {
                  // },
                })}
              />
            </ConfigProvider>
          </>
        )}
      </ViewItems>

      <ViewItems className={"border border-grey mt-6 rounded px-5 py-3 gap-2"}>
        <ViewReverse className={"w-full justify-between"}>
          <Text
            text={getContentKey("tadawul_most_active_symbols")}
            color="text-primary-light"
            size={fontSizes.h14}
          />
          <ViewReverse className={"gap-2 items-center"}>
            <div className="w-4 h-4 bg-grey rounded-full" />
            <ReactSVG src={icons.filter} />
            <ReactSVG src={icons.roundArrow} className="opacity-25" />
            <ReactSVG
              className="cursor-pointer"
              src={
                isDetailOpen.activeSymbolsTable
                  ? icons.smallScreen
                  : icons.enterFullScreen
              }
              onClick={() => toogleFullScreen("activeSymbolsTable")}
            />
          </ViewReverse>
        </ViewReverse>

        {isDetailOpen.activeSymbolsTable && (
          <>
            <ConfigProvider
              tabs={{
                style: {
                  color: "red",
                },
              }}
              theme={{
                components: {
                  Tabs: {
                    cardBg: "white",
                    // colorBorderSecondary: 'transparent',
                  },
                },
              }}
            >
              <Tabs
                defaultActiveKey="1"
                type="card"
                className="w-full"
                items={activeSymbolsTabs(getContentKey)}
              />
            </ConfigProvider>

            <ConfigProvider
              theme={{
                components: {
                  Table: {
                    headerBg: "#E0E0E0",
                    headerSplitColor: "transparent",
                    borderRadius: 0,
                    padding: 15,
                  },
                },
              }}
            >
              <Table
                columns={
                  isArabic
                    ? gainLossColumns(TextWeight, getContentKey)?.reverse()
                    : gainLossColumns(TextWeight, getContentKey)
                }
                dataSource={gainLossData}
                pagination={false}
                className="rounded-tr-[10px] rounded-tl-[10px] border-t-grey border overflow-hidden"
                scroll={{
                  x: "max-content",
                  y: 250,
                }}
                locale={{ emptyText: "No data available in table" }}
                onRow={(i) => ({
                  // onClick: (e) => {
                  // },
                })}
              />
            </ConfigProvider>
          </>
        )}
      </ViewItems>

      <ViewReverse
        className={`w-full gap-4 flex-col ${rowReverseStylesm(isArabic)}`}
      >
        <ViewItems
          className={
            "w-full sm:w-1/2 border border-grey mt-6 rounded px-5 py-3 gap-2"
          }
        >
          <ViewReverse className={"w-full justify-between"}>
            <Text
              text={getContentKey("tadawul_most_active_symbols")}
              color="text-primary-light"
              size={fontSizes.h14}
            />
            <ViewReverse className={"gap-2 items-center"}>
              <div className="w-4 h-4 bg-grey rounded-full" />
              <ReactSVG src={icons.filter} />
              <ReactSVG
                className="cursor-pointer"
                src={
                  isDetailOpen.activeSymbolsList
                    ? icons.smallScreen
                    : icons.enterFullScreen
                }
                onClick={() => toogleFullScreen("activeSymbolsList")}
              />
            </ViewReverse>
          </ViewReverse>

          {isDetailOpen.activeSymbolsList && (
            <div className="max-h-96 overflow-y-scroll w-full">
              {indicesData.map((item, index) => (
                <ViewItems
                  className={"w-full py-2 px-4 border-b border-b-grey"}
                >
                  <ViewReverse className={"w-full justify-between"}>
                    <ViewReverse className={"gap-4"}>
                      <ReactSVG src={icons.dots} />
                      <ViewItems className={"-mt-1"}>
                        <Text
                          text={item.name}
                          color="text-dark-blue"
                          size={fontSizes.h14}
                        />
                        <Text text={item.desc} color="text-light-blue" />
                      </ViewItems>
                    </ViewReverse>
                    <ViewItems>
                      <ViewReverse
                        className={"items-center gap-2 w-full justify-end"}
                      >
                        <Text
                          text={item.price.toLocaleString()}
                          color="text-dark-blue"
                          size={fontSizes.h14}
                        />
                        <ReactSVG src={item.isDown ? icons.down : icons.up} />
                      </ViewReverse>
                      <Text text={item.percent} color="text-red-500" />
                    </ViewItems>
                  </ViewReverse>
                </ViewItems>
              ))}
            </div>
          )}
        </ViewItems>

        <ViewItems
          className={
            "w-full sm:w-1/2 border border-grey mt-6 rounded px-5 py-3 gap-2"
          }
        >
          <ViewReverse className={"w-full justify-between"}>
            <Text
              text={getContentKey("tadawul_most_active")}
              color="text-primary-light"
              size={fontSizes.h14}
            />
            <ViewReverse className={"gap-2 items-center"}>
              <ReactSVG src={icons.filter} />
              <ReactSVG
                className="cursor-pointer"
                src={
                  isDetailOpen.mostActiveList
                    ? icons.smallScreen
                    : icons.enterFullScreen
                }
                onClick={() => toogleFullScreen("mostActiveList")}
              />
            </ViewReverse>
          </ViewReverse>

          {isDetailOpen.mostActiveList && (
            <>
              <ConfigProvider
                tabs={{
                  style: {
                    color: "red",
                  },
                }}
                theme={{
                  components: {
                    Tabs: {
                      cardBg: "white",
                      // colorBorderSecondary: 'transparent',
                    },
                  },
                }}
              >
                <Tabs
                  defaultActiveKey="1"
                  type="card"
                  className="w-full"
                  items={activeSymbolsTabs(getContentKey)}
                />
              </ConfigProvider>

              <div className="max-h-80 overflow-y-scroll w-full">
                {indicesData.map((item, index) => (
                  <ViewItems
                    className={"w-full py-2 px-4 border-b border-b-grey"}
                  >
                    <ViewReverse className={"w-full justify-between"}>
                      <ViewReverse className={"gap-4"}>
                        <ReactSVG src={icons.dots} />
                        <ViewItems className={"-mt-1"}>
                          <Text
                            text={item.name}
                            color="text-dark-blue"
                            size={fontSizes.h14}
                          />
                          <Text text={item.desc} color="text-light-blue" />
                        </ViewItems>
                      </ViewReverse>
                      <ViewItems>
                        <ViewReverse
                          className={"items-center gap-2 w-full justify-end"}
                        >
                          <Text
                            text={item.price.toLocaleString()}
                            color="text-dark-blue"
                            size={fontSizes.h14}
                          />
                          <ReactSVG src={item.isDown ? icons.down : icons.up} />
                        </ViewReverse>
                        <Text text={item.percent} color="text-red-500" />
                      </ViewItems>
                    </ViewReverse>
                  </ViewItems>
                ))}
              </div>
            </>
          )}
        </ViewItems>
      </ViewReverse>

      <ViewItems
        className={"w-full border border-grey mt-6 rounded px-5 py-3 gap-2"}
      >
        <ViewReverse className={"w-full justify-between"}>
          <Text
            text={getContentKey("news")}
            color="text-primary-light"
            size={fontSizes.h14}
          />
          <ViewReverse className={"gap-2 items-center"}>
            <div className="w-4 h-4 bg-grey rounded-full" />
            <ReactSVG src={icons.filter} />
            <ReactSVG
              className="cursor-pointer"
              src={
                isDetailOpen.news ? icons.smallScreen : icons.enterFullScreen
              }
              onClick={() => toogleFullScreen("news")}
            />
          </ViewReverse>
        </ViewReverse>

        {isDetailOpen.news && (
          <ViewReverse className={"w-full gap-4"}>
            <ViewItems
              className={
                "w-1/3 border-r border-r-grey max-h-96 overflow-scroll pe-4"
              }
            >
              <Text
                text="https://www.tadawul.com.sa/wps/poc?uri=link:Announcement&locale=ar&anCat=1&anld53252"
                size={fontSizes.h14}
                weight={TextWeight.light}
                color="text-dark-blue"
              />
              <Text
                text="TADAWUL, SSP, 01/01/2019 19:06"
                size={fontSizes.h14}
                weight={TextWeight.light}
                color="text-light-blue"
              />
            </ViewItems>
            <ViewItems
              className={
                "w-1/3 border-r border-r-grey max-h-96 overflow-scroll pe-4"
              }
            >
              <Text
                text="https://www.tadawul.com.sa/wps/poc?uri=link:Announcement&locale=ar&anCat=1&anld53252"
                size={fontSizes.h14}
                weight={TextWeight.light}
                color="text-dark-blue"
              />
              <Text
                text="TADAWUL, SSP, 01/01/2019 19:06"
                size={fontSizes.h14}
                weight={TextWeight.light}
                color="text-light-blue"
              />
            </ViewItems>
            <ViewItems className={"w-1/3 max-h-96 overflow-scroll pe-4"}>
              <Text
                text="https://www.tadawul.com.sa/wps/poc?uri=link:Announcement&locale=ar&anCat=1&anld53252"
                size={fontSizes.h14}
                weight={TextWeight.light}
                color="text-dark-blue"
              />
              <Text
                text="TADAWUL, SSP, 01/01/2019 19:06"
                size={fontSizes.h14}
                weight={TextWeight.light}
                color="text-light-blue"
              />
            </ViewItems>
          </ViewReverse>
        )}
      </ViewItems>
    </div>
  );
};

export default Market;
