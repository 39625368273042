import React from "react";
import Sidebar from "../../components/Sidebar";
import helpFunctional from "./index.function";
import { helpMenu } from "../../config/config";
import Faqs from "./components/Faqs";
import Contact from "./components/Contact";

const Help = () => {
  const { selected, onPressMenu } = helpFunctional();

  return (
    <Sidebar selected={selected} onPressMenu={onPressMenu} menuList={helpMenu}>
      {selected === "faqs" ? (
        <Faqs />
      ) : selected == "contact" ? (
        <Contact />
      ) : (
        <Faqs />
      )}
    </Sidebar>
  );
};

export default Help;
