import apiServices from "../../ApiServices/request-handler";
import { setToken } from "../../utils/utils";
import {
  setAuthLoading,
  setLoginError,
  setOtpError,
  setUserData,
  setUserToken,
} from "../slices/authSlice";

export const loginUser = (data, onDone) => {
  return async (dispatch) => {
    try {
      dispatch(setAuthLoading(true));
      const response = await apiServices.login(data);
      dispatch(setUserData(response?.result));
      dispatch(setAuthLoading(false));
      onDone();
    } catch (error) {
      console.log("error", error)
      dispatch(setAuthLoading(false));
      dispatch(setLoginError(error?.data?.message));
    }
  };
};

export const verifyUserOtp = (data, user, onDone) => {
  return async (dispatch) => {
    try {
      dispatch(setAuthLoading(true));
      const response = await apiServices.verifyOtp(data);
      dispatch(setAuthLoading(false));
      dispatch(setUserToken(response?.result?.accessToken));
      setToken(response?.result?.accessToken);
      dispatch(setUserData({...user, ...response?.result}));
      onDone();
    } catch (error) {
      console.log('errrr', error)
      dispatch(setAuthLoading(false));
      dispatch(setOtpError(error?.data?.message || error?.data?.errors?.OtpCode?.[0]));
    }
  };
};

export const resendOtpCode = (data, onDone) => {
  return async (dispatch) => {
    try {
      const response = await apiServices.resendCode(data);
      onDone();
    } catch (error) {
    }
  };
};
