import React from "react";
import DashboardHeader from "../../components/DashboardHeader";
import ViewReverse from "../../components/ViewReverse";
import Text from "../../components/Text";
import dashboardFunctional from "./index.function";
import Settings from "../Settings";
import MyInvestments from "../MyInvestments";
import Funds from "../Funds";
import Report from "../report";
import TransferMain from "../Transfer";
import Trading from "../Trading";
import Help from "../help";

const Dashboard = () => {
  const { TextWeight, currentTab, getContentKey } = dashboardFunctional();

  return (
    <div className="bg-sky-blue">
      <DashboardHeader />

      <div className="px-4 py-10">
        {currentTab === "investments" ? (
          <MyInvestments />
        ) : currentTab == "funds" ? (
          <Funds />
        ) : currentTab === "settings" ? (
          <Settings />
        ) : currentTab === "reports" ? (
          <Report/>
        ) : currentTab === "trading" ? (
          <Trading />
        ) : currentTab === "transfers" ? (
          <TransferMain />
        ) : currentTab === "help" ? (
          <Help />
        ) : null}
      </div>

      <ViewReverse className="items-center justify-between px-4 pb-8 flex-col sm:flex-row">
        <ViewReverse
          className={"items-center cursor-pointer flex-col sm:flex-row"}
        >
          <Text
            text={`${getContentKey("last_login")}: `}
            color={"text-dark-grey"}
            className={"opacity-50"}
          />
          <Text
            text={" 12 November 2020 at 17:39 BST"}
            color={"text-dark-grey"}
            className={"opacity-50"}
          />
        </ViewReverse>
        <ViewReverse className={`items-center gap-10 flex-col sm:flex-row`}>
          <Text
            text={getContentKey("terms_conditions")}
            color={"text-light-blue"}
            weight={TextWeight.bold}
            className={"cursor-pointer"}
          />
          <Text
            text={getContentKey("info_security")}
            color={"text-light-blue"}
            weight={TextWeight.bold}
            className={"cursor-pointer"}
          />
          <Text
            text={`2021 © ${getContentKey("copyright")}`}
            color={"text-dark-grey"}
            weight={TextWeight.light}
            className={"cursor-pointer"}
          />
        </ViewReverse>
      </ViewReverse>
    </div>
  );
};

export default Dashboard;
