import { ConfigProvider, Modal } from "antd";
import React, { useState } from "react";
import ViewItems from "./ViewItems";
import Text from "./Text";
import { fontSizes } from "../constants/constants";
import CustomDropdown from "./CustomDropdown";
import { dropdownOptions } from "../config/config";
import CustomInput from "./CustomInput";
import ViewReverse from "./ViewReverse";
import dynamicfunction from "../utils/dynamicfunction";
import { ReactSVG } from "react-svg";
import icons from "../constants/icons";
import Button from "./Button";
import { useSelector } from "react-redux";
import { getContentKeyAccToLang } from "../utils/utils";

const BuyModal = ({ isOpen = false, onClose, onClickDetailed }) => {
  const { tradingContent } = useSelector((state) => state.content);
  const { isArabic, TextWeight } = dynamicfunction();

  const [buyingDetails, setBuyingDetails] = useState({
    selectedOption: "",
    orderLimit: "",
  });
  const [isPriceOptions, setIsPriceOptions] = useState(false);

  const handleBuyingDetails = (key, val) =>
    setBuyingDetails((prev) => ({ ...prev, [key]: val }));

  const getContentKey = (key) => {
    return getContentKeyAccToLang(
      tradingContent?.web_trading_order?.data?.attributes,
      key,
      isArabic
    );
  };

  return (
    <div>
      {isOpen && (
        <div>
          <div className="fixed inset-0 w-full z-20 h-screen bg-black bg-opacity-30" />
          <div
            className={`w-80 absolute bg-white z-50 top-10 shadow-lg rounded ${
              isArabic ? "left-0" : "right-0"
            }`}
          >
            <ViewItems className={"w-full p-4"}>
              <Text
                text={getContentKey("buy")}
                color="text-green-500"
                size={fontSizes.h20}
                className={"mb-4"}
              />
              <CustomDropdown
                label={""}
                options={dropdownOptions}
                selectedValue={buyingDetails.selectedOption}
                onSelect={(val) => handleBuyingDetails("selectedOption", val)}
                top="top-1"
              />
              <CustomInput
                label={""}
                placeholder={"1020-Al-Jazira Bank"}
                isFullborder
                isRounded
                divClasses={"my-2"}
              />
              <ViewReverse className={"w-full gap-4"}>
                <div className="w-1/2">
                  <CustomDropdown
                    options={dropdownOptions}
                    selectedValue={buyingDetails.orderLimit}
                    onSelect={(val) => handleBuyingDetails("orderLimit", val)}
                    top="top-1"
                  />
                </div>
                <ViewReverse
                  className={
                    "relative w-1/2 mt-1 border border-grey px-2 rounded items-center justify-between"
                  }
                >
                  <Text text="10" size={fontSizes.h15} className={"mt-1"} />
                  <Text
                    text="+"
                    color="text-dark-grey"
                    size={fontSizes.h24}
                    weight={TextWeight.bold}
                    className={"mt-1 cursor-pointer"}
                    onClick={() => setIsPriceOptions(!isPriceOptions)}
                  />
                  {isPriceOptions && (
                    <ViewReverse className="absolute right-0 top-8 bg-white gap-6 rounded shadow-lg p-4">
                      <div className="flex flex-col items-end gap-2">
                        <Text
                          text="+10"
                          size={fontSizes.h14}
                          weight={TextWeight.light}
                        />
                        <Text
                          text="-10"
                          size={fontSizes.h14}
                          weight={TextWeight.light}
                        />
                      </div>
                      <div className="flex flex-col items-end gap-2">
                        <Text
                          text="+100"
                          size={fontSizes.h14}
                          weight={TextWeight.light}
                        />
                        <Text
                          text="-100"
                          size={fontSizes.h14}
                          weight={TextWeight.light}
                        />
                      </div>
                      <div className="flex flex-col items-end gap-2">
                        <Text
                          text="+1,000"
                          size={fontSizes.h14}
                          weight={TextWeight.light}
                        />
                        <Text
                          text="-1,000"
                          size={fontSizes.h14}
                          weight={TextWeight.light}
                        />
                      </div>
                      <div className="flex flex-col items-end gap-2">
                        <Text
                          text="+10,000"
                          size={fontSizes.h14}
                          weight={TextWeight.light}
                        />
                        <Text
                          text="-10,000"
                          size={fontSizes.h14}
                          weight={TextWeight.light}
                        />
                      </div>
                    </ViewReverse>
                  )}
                </ViewReverse>
              </ViewReverse>

              <ViewReverse className={"w-full my-3"}>
                <div
                  className={`flex flex-col justify-center items-center w-1/2 border-2 border-light-blue ${
                    isArabic ? "rounded-tr rounded-br" : "rounded-tl rounded-bl"
                  }  p-2`}
                >
                  <Text
                    text={getContentKey("best_bid")}
                    size={fontSizes.h14}
                    weight={TextWeight.light}
                  />
                  <Text
                    text="13.88 (9,285)"
                    size={fontSizes.h14}
                    weight={TextWeight.light}
                  />
                </div>
                <div
                  className={`flex flex-col justify-center items-center w-1/2 bg-light-blue ${
                    isArabic ? "rounded-tl rounded-bl" : "rounded-tr rounded-br"
                  } p-2`}
                >
                  <Text
                    text={getContentKey("best_offer")}
                    size={fontSizes.h14}
                    weight={TextWeight.light}
                    color="text-white"
                  />
                  <Text
                    text="13.90 (10,000)"
                    size={fontSizes.h14}
                    weight={TextWeight.light}
                    color="text-white"
                  />
                </div>
              </ViewReverse>

              <ViewReverse
                className={
                  "justify-between items-center w-full py-3 px-4 border border-grey rounded"
                }
              >
                <ReactSVG src={icons.minus} />
                <Text text="13.86" size={fontSizes.h15} />
                <ReactSVG src={icons.plus} />
              </ViewReverse>

              <Text
                text="138.00"
                size={fontSizes.h22}
                weight={TextWeight.bold}
                className={"mx-auto my-6"}
              />

              <ViewReverse className="items-center w-full justify-between gap-4">
                <Button
                  text={getContentKey("buy")}
                  bgColor="bg-green-600"
                  color={"text-white"}
                  className={"w-1/2 text-center border-opacity-50"}
                  onClick={onClose}
                />
                <Button
                  text={getContentKey("detailed_order")}
                  bgColor="bg-white"
                  color="text-black"
                  className={"w-1/2 border border-grey text-center"}
                  onClick={onClickDetailed}
                />
              </ViewReverse>
            </ViewItems>
          </div>
        </div>
      )}
    </div>
  );
};

export default BuyModal;
