import Swal from "sweetalert2";

export const showToast = (icon, title, text, footer, image, position = "bottom-end", props) => {
  const Toast = Swal.mixin({
    toast: true,
    position,
    showConfirmButton: false,
    timer: 2000,
    timerProgressBar: true,
    didOpen: (toast) => {
      toast.onmouseenter = Swal.stopTimer;
      toast.onmouseleave = Swal.resumeTimer;
    },
  });

  Toast.fire({
    imageUrl: image,
    icon,
    title,
    text,
    footer,
    ...props,
  });
};

export const getContentKeyAccToLang = (data, key, isArabic) => {
  if (data) {
    if (isArabic) return data[key + "_arabic"];
    return data[key];
  }
  return "";
};

export const setToken = (token) => localStorage.setItem("token", JSON.stringify(token))
export const getToken = () => localStorage.getItem("token")