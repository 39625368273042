import downArrow from '../assets/icons/down_arrow.svg';
import search from '../assets/icons/search.svg';
import menuList from '../assets/icons/menu_list.svg';
import back from '../assets/icons/back.svg';
import bell from '../assets/icons/bell.svg';
import downArrowYellow from '../assets/icons/down_arrow_yellow.svg';
import downArrowWhite from '../assets/icons/down_arrow_white.svg';
import person from '../assets/icons/person.svg';
import state from '../assets/icons/state.svg';
import stateSelected from '../assets/icons/state_selected.svg';
import selectedBell from '../assets/icons/selected_bell.svg';
import selectedPerson from '../assets/icons/selected_person.svg';
import selectedState from '../assets/icons/selected_states.svg';
import dropdownArrow from '../assets/icons/dropdown_arrow.svg';
import openLink from '../assets/icons/open_link.svg';
import home from '../assets/icons/home.svg';
import homeSelected from '../assets/icons/home_selected.svg';
import pieChart from '../assets/icons/pie_chart.svg';
import pieChartSelected from '../assets/icons/pie_chart_selected.svg';
import bag from '../assets/icons/bag.svg';
import bagSelected from '../assets/icons/bag_selected.svg';
import upArrowSelected from '../assets/icons/up_arrow_selected.svg';
import plus from '../assets/icons/plus.svg';
import plusSelected from '../assets/icons/plus_selected.svg';
import download from '../assets/icons/download.svg';
import downloadSelected from '../assets/icons/download_selected.svg';
import roundArrow from '../assets/icons/round_arrow.svg';
import roundArrowSelected from '../assets/icons/round_arrow_selected.svg';
import slip from '../assets/icons/slip.svg';
import slipSelected from '../assets/icons/slip_selected.svg';
import cross from '../assets/icons/cross.svg';
import rightArow from '../assets/icons/right_arrow.svg';
import settings from '../assets/icons/settings.svg';
import dots from '../assets/icons/dots.svg';
import modalCross from '../assets/icons/modal_cross.svg';
import edit from '../assets/icons/edit.svg';
import investment from '../assets/icons/investment.svg';
import selectedInvestment from '../assets/icons/selected_investment.svg';
import statement from '../assets/icons/statement.svg';
import selectedStatement from '../assets/icons/selected_statement.svg';
import pdf from '../assets/icons/pdf.svg';
import tableCross from '../assets/icons/table_cross.svg';
import twoPerson from '../assets/icons/two_person.svg';
import twoPersonSelected from '../assets/icons/two_person_selected.svg';
import monitorGraph from '../assets/icons/monitor_graph.svg';
import monitorGraphSelected from '../assets/icons/monitor_graph_selected.svg';
import ordersList from '../assets/icons/order_list.svg';
import orderListSelected from '../assets/icons/order_list_selected.svg';
import letterT from '../assets/icons/letter_t.svg';
import letterTSelected from '../assets/icons/letter_t_selected.svg';
import filter from '../assets/icons/filter.svg';
import down from '../assets/icons/down.svg';
import up from '../assets/icons/up.svg';
import smallScreen from '../assets/icons/small_screen.svg';
import enterFullScreen from '../assets/icons/enter_full_screen.svg';
import tick from '../assets/icons/tick.svg';
import play from '../assets/icons/play.svg';
import delete_icon from '../assets/icons/delete.svg';
import lossArrow from '../assets/icons/loss_arrow.svg';
import profitArrow from '../assets/icons/profit_arrow.svg';
import minus from '../assets/icons/minus.svg';
import rightAngle from '../assets/icons/right_angle.svg';
import calculator from '../assets/icons/calculator.svg';
import selectedComment from '../assets/icons/selected_comment.svg';
import comment from '../assets/icons/comment.svg';
import upwardArrow from '../assets/icons/upward_arrow.svg';
import downwardArrow from '../assets/icons/downward_arrow.svg';

const icons = {
    downArrow,
    search,
    menuList,
    back,
    bell,
    downArrowWhite,
    downArrowYellow,
    person,
    state,
    selectedBell,
    selectedPerson,
    selectedState,
    dropdownArrow,
    openLink,
    home,
    homeSelected,
    pieChart,
    pieChartSelected,
    bag,
    bagSelected,
    upArrowSelected,
    plus,
    plusSelected,
    download,
    downloadSelected,
    roundArrow,
    roundArrowSelected,
    slip,
    slipSelected,
    cross,
    rightArow,
    settings,
    dots,
    modalCross,
    edit,
    investment,
    selectedInvestment,
    statement,
    selectedStatement,
    pdf,
    tableCross,
    stateSelected,
    twoPerson,
    twoPersonSelected,
    monitorGraph,
    monitorGraphSelected,
    ordersList,
    orderListSelected,
    letterT,
    letterTSelected,
    filter,
    down,
    up,
    smallScreen,
    enterFullScreen,
    tick,
    play,
    delete_icon,
    lossArrow,
    profitArrow,
    minus,
    rightAngle,
    calculator,
    selectedComment,
    comment,
    upwardArrow,
    downwardArrow,
};

export default icons;