import React from "react";
import ViewReverse from "../../../components/ViewReverse";
import Text from "../../../components/Text";
import dynamicfunction from "../../../utils/dynamicfunction";
import { fontSizes } from "../../../constants/constants";
import ViewItems from "../../../components/ViewItems";
import CustomTable from "../../../components/CustomTable";
import { useSelector } from "react-redux";
import Loader from "../../../components/Loader";
import { getContentKeyAccToLang } from "../../../utils/utils";
import { rowReverseStylesm } from "../../../utils/styles";

const Overview = () => {
  const { TextWeight, isArabic } = dynamicfunction();
  const { clientDetails } = useSelector((state) => state.investments);
  const { investmentContent } = useSelector((state) => state.content);

  const getContentKey = (key) => {
    return getContentKeyAccToLang(
      investmentContent?.web_investment_overview?.data?.attributes,
      key,
      isArabic,
    );
  };

  return (
    <div>
      {clientDetails ? (
        <div className="md:pb-32">
          <ViewReverse className={"items-end justify-between"}>
            <ViewReverse className={"gap-1"}>
              <Text
                text={getContentKey("welcome")}
                weight={TextWeight.headingBold}
                size={fontSizes.h24}
                color={"text-dark-blue"}
              />
              <Text
                text={clientDetails?.clientInformation?.clientName}
                weight={TextWeight.headingBold}
                size={fontSizes.h24}
                color={"text-dark-blue"}
              />
            </ViewReverse>
            <ViewReverse className={"items-center gap-2"}>
              <Text text={"A"} color={"text-dark-grey"} />
              <Text
                text={"A"}
                size={fontSizes.h16}
                color={"text-dark-grey"}
                className={"mb-[1px]"}
              />
              <Text
                text={"A"}
                size={fontSizes.h20}
                color={"text-dark-grey"}
                className={"mb-[2px]"}
              />
            </ViewReverse>
          </ViewReverse>

          <ViewReverse className={`mt-4 gap-4 flex-col ${rowReverseStylesm(isArabic)}`}>
            <ViewItems className={"border border-grey px-5 py-3 rounded md:w-3/5"}>
              <Text
                text={getContentKey("overview")}
                size={fontSizes.h14}
                color={"text-primary-light"}
                className={"mb-3"}
              />

              <CustomTable
                tableData={getTableData(clientDetails?.clientInformation, getContentKey)}
              />
            </ViewItems>
            <ViewItems className={"md:w-2/5 gap-4"}>
              <ViewItems
                className={"border border-grey px-5 py-3 rounded w-full pb-16"}
              >
                <Text
                  text={`${getContentKey("account_number")} ${clientDetails?.clientInformation?.accountBalance}`}
                  size={fontSizes.h14}
                  color={"text-primary-light"}
                  className={"mb-3"}
                />
                <ViewReverse className={"gap-10"}>
                  <ViewItems className={"gap-2"}>
                    <Text text={getContentKey("client_since")} isColon />
                    <Text text={getContentKey("report_as")} isColon />
                    <Text text={getContentKey("reporting_currency")} isColon />
                  </ViewItems>
                  <ViewItems className={"gap-2"}>
                    <Text text={clientDetails?.clientInformation?.clientSince } />
                    <Text text={clientDetails?.clientInformation?.reportingAsOf} />
                    <Text text={clientDetails?.clientInformation?.reportingCurrency} />
                  </ViewItems>
                </ViewReverse>
              </ViewItems>
              <ViewItems
                className={"border border-grey px-5 py-3 rounded w-full"}
              >
                <Text
                  text={getContentKey("relationship")}
                  size={fontSizes.h14}
                  color={"text-primary-light"}
                  className={"mb-3"}
                />
                <ViewReverse className={"gap-10"}>
                  <ViewItems className={"gap-2"}>
                    <Text text={getContentKey("name")} isColon />
                    <Text text={getContentKey("email")} isColon />
                    <Text text={getContentKey("landline")} isColon />
                  </ViewItems>
                  <ViewItems className={"gap-2"}>
                    <Text text={clientDetails?.clientInformation?.clientName} />
                    <Text
                      text={clientDetails?.clientInformation?.clientEmail}
                      className={"underline"}
                    />
                    <Text text={clientDetails?.clientInformation?.clientContact} />
                  </ViewItems>
                </ViewReverse>
              </ViewItems>
            </ViewItems>
          </ViewReverse>
        </div>
      ) : (
        <Loader />
      )}
    </div>
  );
};

export default Overview;

const getTableData = (clientDetails, getContentKey) => {
  return [
    {
      id: 0,
      col1: "",
      col2: getContentKey("balance"),
      col3: getContentKey("asset_percentage"),
    },
    {
      id: 1,
      col1: getContentKey("manage_investment"),
      col2: clientDetails?.managedInvestmentAmount?.toLocaleString(),
      col3: clientDetails?.managedInvestmentPercent,
    },
    {
      id: 2,
      col1: getContentKey("brokerage"),
      col2: clientDetails?.brokerageAmount?.toLocaleString(),
      col3: clientDetails?.brokeragePercent,
    },
    {
      id: 3,
      col1: getContentKey("total"),
      col2: clientDetails?.accountBalance?.toLocaleString(),
      col3: "100%",
    },
  ];
};
