import React from "react";
import ViewReverse from "../../../components/ViewReverse";
import Text from "../../../components/Text";
import dynamicfunction from "../../../utils/dynamicfunction";
import { fontSizes } from "../../../constants/constants";
import ViewItems from "../../../components/ViewItems";
import { allocationData } from "../../../config/config";
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from "chart.js";
import { Doughnut } from "react-chartjs-2";

ChartJS.register(ArcElement, Tooltip, Legend);

let assetClassData = [
  {
    label: "Public equity",
    value: 41.06,
    color: "#354C9F",
    cutout: "65%",
  },
  {
    label: "Private equity",
    value: 36.24,
    color: "#6076C4",
    cutout: "65%",
  },
  {
    label: "Money markets",
    value: 12.58,
    color: "#D16440",
    cutout: "65%",
  },
  {
    label: "Real estate",
    value: 7.73,
    color: "#DB9828",
    cutout: "65%",
  },
  {
    label: "Cash",
    value: 3.39,
    color: "#8F7757",
    cutout: "65%",
  },
];

let geographyData = [
  {
    label: "Saudi Arabia",
    value: 93.62,
    color: "#354C9F",
    cutout: "65%",
  },
  {
    label: "United Arab Emirates",
    value: 6.38,
    color: "#6076C4",
    cutout: "65%",
  },
];

const options = {
  plugins: {
    legend: {
      display: true,
      position: "right",
      labels: {
        boxWidth: 15,
        usePointStyle: true,
        pointStyle: "circle",
        padding: 20,
      },
    },
    tooltip: {
      enabled: true,
    },
  },
  responsive: true,
  cutout: assetClassData.map((item) => item.cutout),
};

const finalAssetClassData = {
  labels: assetClassData.map((item) => item.label),
  datasets: [
    {
      data: assetClassData.map((item) => Math.round(item.value)),
      backgroundColor: assetClassData.map((item) => item.color),
      borderColor: assetClassData.map((item) => item.color),
      dataVisibility: new Array(assetClassData.length).fill(true),
    },
  ],
};

const finalGeographyData = {
  labels: geographyData.map((item) => item.label),
  datasets: [
    {
      data: geographyData.map((item) => Math.round(item.value)),
      backgroundColor: geographyData.map((item) => item.color),
      borderColor: geographyData.map((item) => item.color),
      dataVisibility: new Array(geographyData.length).fill(true),
    },
  ],
};

const Allocation = () => {
  const { TextWeight } = dynamicfunction();
  return (
    <div>
      <ViewReverse className={"items-end justify-between mb-4"}>
        <Text
          text={`Managed investments: Allocation`}
          weight={TextWeight.headingBold}
          size={fontSizes.h24}
          color={"text-dark-blue"}
        />
        <ViewReverse className={"items-center gap-2"}>
          <Text text={"A"} color={"text-dark-grey"} />
          <Text
            text={"A"}
            size={fontSizes.h16}
            color={"text-dark-grey"}
            className={"mb-[1px]"}
          />
          <Text
            text={"A"}
            size={fontSizes.h20}
            color={"text-dark-grey"}
            className={"mb-[2px]"}
          />
        </ViewReverse>
      </ViewReverse>

      <ViewReverse className={"w-full mt-4 gap-4"}>
        <ViewItems className={"w-1/2 border border-grey rounded p-4"}>
          <Text
            text={"Asset Allocation"}
            size={fontSizes.h14}
            color={"text-primary-light"}
          />

          <div className="w-full border border-grey rounded">
            <div className="grid grid-cols-3 p-4 bg-grey">
              <Text text={"Asset class"} size={fontSizes.h14} />
              <Text
                text={"Market value"}
                size={fontSizes.h14}
                className={"text-right"}
              />
              <Text
                text={"% of net assets"}
                size={fontSizes.h14}
                className={"text-right"}
              />
            </div>
            {allocationData.map((item) => (
              <div className="grid grid-cols-3 p-4 border-b border-b-grey">
                <ViewItems className={"col-span-1"}>
                  <Text
                    text={item.class}
                    size={fontSizes.h14}
                    weight={TextWeight.light}
                  />
                </ViewItems>
                <div className={"flex flex-col item-end col-span-1"}>
                  <Text
                    text={item.value}
                    size={fontSizes.h14}
                    className={"text-right"}
                    weight={TextWeight.light}
                  />
                </div>
                <div className={"flex flex-col item-end col-span-1"}>
                  <Text
                    text={item.assetPercent}
                    size={fontSizes.h14}
                    className={"text-right"}
                    weight={TextWeight.light}
                  />
                </div>
              </div>
            ))}
            <div className="grid grid-cols-3 p-4 bg-grey bg-opacity-50">
              <Text
                text={"Total"}
                color="text-light-blue"
                size={fontSizes.h14}
                weight={TextWeight.light}
              />
              <Text
                text={"176,865,715.45"}
                size={fontSizes.h14}
                className={"text-right"}
                color="text-light-blue"
                weight={TextWeight.light}
              />
              <Text
                text={"100%"}
                size={fontSizes.h14}
                weight={TextWeight.light}
                className={"text-right"}
                color="text-light-blue"
              />
            </div>
          </div>
        </ViewItems>

        <ViewItems className={"w-1/2 gap-4"}>
          <ViewItems className={"w-full border border-grey rounded p-4"}>
            <Text
              text={"By asset class"}
              size={fontSizes.h14}
              color={"text-primary-light"}
            />

            <div className="h-72 -mt-10 -mb-10 w-full flex justify-center items-center">
              <Doughnut data={finalAssetClassData} options={options} />
            </div>
          </ViewItems>

          <ViewItems className={"w-full border border-grey rounded p-4"}>
            <Text
              text={"By geography"}
              size={fontSizes.h14}
              color={"text-primary-light"}
            />

            <div className="h-72 -mt-10 -mb-10 w-full flex justify-center items-center">
              <Doughnut data={finalGeographyData} options={options} />
            </div>
          </ViewItems>
        </ViewItems>
      </ViewReverse>
    </div>
  );
};

export default Allocation;
