import React, { useEffect, useState } from "react";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import Login from "../pages/Login";
import Home from "../pages/Home";
import Dashboard from "../pages/Dashboard";
import { getStrapiContent } from "../store/actions/contentAction";
import { useDispatch } from "react-redux";
import ProtectedRoute from "../components/ProtectedRoute";
import FullScreenLoader from "../components/FullScreenLoader";

const Router = () => {
  const dispatch = useDispatch();

  useEffect(() => {
    const getContent = async () => {
      dispatch(getStrapiContent());
    };
    getContent();
  }, []);

  return (
    <BrowserRouter>
      <Routes>
        <Route index element={<Home />} />
        <Route path={"/login"} element={<Login />} />
        <Route
          path={"/dashboard"}
          element={
            // <ProtectedRoute>
              <Dashboard />
            // </ProtectedRoute>
          }
        />
      </Routes>
    </BrowserRouter>
  );
};

export default Router;
