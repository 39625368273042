import React, { useState } from "react";
import Text from "../../../components/Text";
import dynamicfunction from "../../../utils/dynamicfunction";
import { fontSizes } from "../../../constants/constants";
import { Collapse } from "antd";
import { ReactSVG } from "react-svg";
import icons from "../../../constants/icons";
import ViewReverse from "../../../components/ViewReverse";
// import { SettingOutlined } from

const text = (
  <p
    style={{
      paddingInlineStart: 24,
    }}
  >
    A dog is a type of domesticated animal. Known for its loyalty and
    faithfulness, it can be found as a welcome guest in many households across
    the world.
  </p>
);

const genExtra = () => <ReactSVG src={icons.plus} />;

const items = [
  {
    key: "1",
    label:
      "Sadipscing et mangere erat det al hore tutti en colorette por ahne?",
    children: <p>{text}</p>,
    extra: genExtra(),
    showArrow: false,
    style: {
      paddingTop: 15,
      paddingBottom: 15,
    },
  },
  {
    key: "2",
    label: "Sed diam dit furore manega volceino dolor fuego em fuerte? ",
    children: <p>{text}</p>,
    extra: genExtra(),
    showArrow: false,
    style: {
      paddingTop: 15,
      paddingBottom: 15,
    },
  },
];

const Faqs = () => {
  const { TextWeight } = dynamicfunction();

  const [isTabOpen, setIsTabOpen] = useState({
    general: true,
    funds: true,
    trading: true,
    transfer: true,
    report: true,
    settings: true,
  });

  const toogleTabView = (key) =>
    setIsTabOpen((prev) => ({
      ...prev,
      [key]: !prev[key],
    }));

  return (
    <div>
      <Text
        text={"Frequently asked questions"}
        weight={TextWeight.headingBold}
        size={fontSizes.h24}
        color={"text-dark-blue"}
      />

      <ViewReverse
        className={"mt-6 mb-4 items-center gap-3 cursor-pointer"}
        onClick={() => toogleTabView("general")}
      >
        <Text text="General" size={fontSizes.h22} color="text-light-blue" />
        <ReactSVG
          src={isTabOpen.general ? icons.upwardArrow : icons.downwardArrow}
        />
      </ViewReverse>

      {isTabOpen.general ? (
        <div className="border-t border-b border-t-grey border-b-grey">
          <Collapse
            // defaultActiveKey={["1"]}
            bordered={false}
            items={items}
            style={{ background: "white" }}
          />
        </div>
      ) : null}

      
      <ViewReverse
        className={"mt-6 mb-4 items-center gap-3 cursor-pointer"}
        onClick={() => toogleTabView("funds")}
      >
        <Text text="Funds" size={fontSizes.h22} color="text-light-blue" />
        <ReactSVG
          src={isTabOpen.funds ? icons.upwardArrow : icons.downwardArrow}
        />
      </ViewReverse>

      {isTabOpen.funds ? (
        <div className="border-t border-b border-t-grey border-b-grey">
          <Collapse
            // defaultActiveKey={["1"]}
            bordered={false}
            items={items}
            style={{ background: "white" }}
          />
        </div>
      ) : null}

      
      <ViewReverse
        className={"mt-6 mb-4 items-center gap-3 cursor-pointer"}
        onClick={() => toogleTabView("trading")}
      >
        <Text text="Trading" size={fontSizes.h22} color="text-light-blue" />
        <ReactSVG
          src={isTabOpen.trading ? icons.upwardArrow : icons.downwardArrow}
        />
      </ViewReverse>

      {isTabOpen.trading ? (
        <div className="border-t border-b border-t-grey border-b-grey">
          <Collapse
            // defaultActiveKey={["1"]}
            bordered={false}
            items={items}
            style={{ background: "white" }}
          />
        </div>
      ) : null}

      
      <ViewReverse
        className={"mt-6 mb-4 items-center gap-3 cursor-pointer"}
        onClick={() => toogleTabView("transfer")}
      >
        <Text text="Transfers" size={fontSizes.h22} color="text-light-blue" />
        <ReactSVG
          src={isTabOpen.transfer ? icons.upwardArrow : icons.downwardArrow}
        />
      </ViewReverse>

      {isTabOpen.transfer ? (
        <div className="border-t border-b border-t-grey border-b-grey">
          <Collapse
            // defaultActiveKey={["1"]}
            bordered={false}
            items={items}
            style={{ background: "white" }}
          />
        </div>
      ) : null}

      
      <ViewReverse
        className={"mt-6 mb-4 items-center gap-3 cursor-pointer"}
        onClick={() => toogleTabView("report")}
      >
        <Text text="Reports" size={fontSizes.h22} color="text-light-blue" />
        <ReactSVG
          src={isTabOpen.report ? icons.upwardArrow : icons.downwardArrow}
        />
      </ViewReverse>

      {isTabOpen.report ? (
        <div className="border-t border-b border-t-grey border-b-grey">
          <Collapse
            // defaultActiveKey={["1"]}
            bordered={false}
            items={items}
            style={{ background: "white" }}
          />
        </div>
      ) : null}

      
      <ViewReverse
        className={"mt-6 mb-4 items-center gap-3 cursor-pointer"}
        onClick={() => toogleTabView("settings")}
      >
        <Text text="Client info, settings and help" size={fontSizes.h22} color="text-light-blue" />
        <ReactSVG
          src={isTabOpen.settings ? icons.upwardArrow : icons.downwardArrow}
        />
      </ViewReverse>

      {isTabOpen.settings ? (
        <div className="border-t border-b border-t-grey border-b-grey">
          <Collapse
            // defaultActiveKey={["1"]}
            bordered={false}
            items={items}
            style={{ background: "white" }}
          />
        </div>
      ) : null}


    </div>
  );
};

export default Faqs;
