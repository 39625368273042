import React from "react";
import Text from "../../../components/Text";
import ViewReverse from "../../../components/ViewReverse";
import { fontSizes } from "../../../constants/constants";
import dynamicfunction from "../../../utils/dynamicfunction";
import ViewItems from "../../../components/ViewItems";
import { ConfigProvider, Table } from "antd";
import { holdingsColumns, holdingsData } from "../../../config/config";

const Holdings = () => {
  const { TextWeight } = dynamicfunction();

  return (
    <div>
      <ViewReverse className={"items-end justify-between"}>
        <ViewReverse className={"gap-1"}>
          <Text
            text={"Managed investments: Holdings"}
            weight={TextWeight.headingBold}
            size={fontSizes.h24}
            color={"text-dark-blue"}
          />
        </ViewReverse>
        <ViewReverse className={"items-center gap-2"}>
          <Text text={"A"} color={"text-dark-grey"} />
          <Text
            text={"A"}
            size={fontSizes.h16}
            color={"text-dark-grey"}
            className={"mb-[1px]"}
          />
          <Text
            text={"A"}
            size={fontSizes.h20}
            color={"text-dark-grey"}
            className={"mb-[2px]"}
          />
        </ViewReverse>
      </ViewReverse>

      <ViewItems className={"border border-grey rounded p-4 mt-4"}>
        <Text
          text={"Managed Investments Account Holdings"}
          size={fontSizes.h14}
          color={"text-primary-light"}
          className={"mb-[2px]"}
        />

        <ConfigProvider
          theme={{
            components: {
              Table: {
                headerBg: "#E0E0E0",
                headerSplitColor: "transparent",
                borderRadius: 0,
                padding: 15,
              },
            },
          }}
        >
          <Table
            columns={holdingsColumns(TextWeight)}
            dataSource={holdingsData}
            pagination={false}
            className="rounded-tr-[10px] rounded-tl-[10px] border-t-grey border overflow-hidden"
            scroll={{
              x: "max-content",
              y: 250,
            }}
            locale={{ emptyText: "No data available in table" }}
            onRow={(i) => ({
              // onClick: (e) => {
              // },
            })}
            summary={() => (
              <Table.Summary.Row>
                <Table.Summary.Cell className="text-light-blue font-bold">Total</Table.Summary.Cell>
                <Table.Summary.Cell className="text-light-blue font-bold">147,938,122.61</Table.Summary.Cell>
                <Table.Summary.Cell className="text-light-blue font-bold">1,884,199.75</Table.Summary.Cell>
                <Table.Summary.Cell className="text-light-blue font-bold">146,053,922.86</Table.Summary.Cell>
                <Table.Summary.Cell className="text-light-blue font-bold">2,976,533.92</Table.Summary.Cell>
                <Table.Summary.Cell className="text-light-blue font-bold">1,083,552.76</Table.Summary.Cell>
                <Table.Summary.Cell className="text-light-blue font-bold">176,865,715.45</Table.Summary.Cell>
                <Table.Summary.Cell className="text-light-blue font-bold">5,944,286.43</Table.Summary.Cell>
                <Table.Summary.Cell className="text-light-blue font-bold">4,060,086.18</Table.Summary.Cell>
                <Table.Summary.Cell className="text-light-blue font-bold">4,060,086.18</Table.Summary.Cell>
              </Table.Summary.Row>
            )}
          />
        </ConfigProvider>

        <ViewItems className={"mt-4"}>
          <ul>
            <li>Market values have been adjusted, where relevant, for capital distributions that took place after the valuation date</li>
            <li>Please refer to the Appendix for Jadwa's valuation policy and currency conversion rates (where applicable) in reference to above investments</li>
          </ul>
        </ViewItems>

      </ViewItems>

      <Text
      text="As of 31 December 2018"
      size={fontSizes.h14}
      className={"mt-4"}
      />
    </div>
  );
};

export default Holdings;
