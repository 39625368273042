import React from "react";
import ViewReverse from "../../../components/ViewReverse";
import Text from "../../../components/Text";
import { fontSizes } from "../../../constants/constants";
import dynamicfunction from "../../../utils/dynamicfunction";
import ViewItems from "../../../components/ViewItems";
import { Line } from "react-chartjs-2";
import {
  Chart as ChartJS,
  LineElement,
  PointElement,
  LinearScale,
  Title,
  Tooltip,
  CategoryScale,
} from "chart.js";

ChartJS.register(
  LineElement,
  PointElement,
  LinearScale,
  Title,
  Tooltip,
  CategoryScale
);

const performanceData = {
  labels: [
    "Dec 12",
    "Jan 13",
    "Feb 14",
    "Sep 14",
    "Apr 15",
    "Nov 15",
    "Jun 16",
    "Jan 17",
    "Aug 17",
    "Mar 18",
    "Oct 18",
  ],
  datasets: [
    {
      label: "Performance",
      data: [100, 120, 150, 130, 180, 170, 160, 190, 180, 200, 210], // Example data points
      borderColor: "#003366", // Line color
      borderWidth: 2,
      pointBackgroundColor: "#003366", // Point color
      pointBorderWidth: 2,
      pointRadius: 4, // Adjusts the size of the point marker
      fill: false, // Disable fill under the line
      tension: 0.4, // Adjusts the smoothness of the line
    },
  ],
};

const performanceOptions = {
  responsive: true,
  // maintainAspectRatio: false,
  scales: {
    x: {
      display: true,
      grid: {
        display: false,
      },
      ticks: {
        color: "#9e9e9e", // x-axis label color
      },
    },
    y: {
      display: true,
      grid: {
        color: "#e0e0e0", // y-axis grid line color
      },
      ticks: {
        color: "#9e9e9e", // y-axis label color
        callback: (value) => `${value}`, // Format y-axis labels if needed
      },
    },
  },
  plugins: {
    legend: {
      display: false, // Hides the legend
    },
    tooltip: {
      enabled: true,
      backgroundColor: "#003366", // Tooltip background color
      titleColor: "#ffffff", // Tooltip title color
      bodyColor: "#ffffff", // Tooltip body color
      displayColors: false, // Hides the color box in tooltip
      callbacks: {
        title: () => "Title",
        label: (context) => `+${context.raw.toFixed(2)}%`,
      },
    },
  },
};

const Performance = () => {
  const { TextWeight } = dynamicfunction();

  return (
    <div>
      <ViewReverse className={"items-end justify-between mb-4"}>
        <Text
          text={`Managed investments: Performance since inception`}
          weight={TextWeight.headingBold}
          size={fontSizes.h24}
          color={"text-dark-blue"}
        />
        <ViewReverse className={"items-center gap-2"}>
          <Text text={"A"} color={"text-dark-grey"} />
          <Text
            text={"A"}
            size={fontSizes.h16}
            color={"text-dark-grey"}
            className={"mb-[1px]"}
          />
          <Text
            text={"A"}
            size={fontSizes.h20}
            color={"text-dark-grey"}
            className={"mb-[2px]"}
          />
        </ViewReverse>
      </ViewReverse>

      <ViewReverse className={"w-full gap-4 mt-6"}>
        <ViewItems className={"w-1/2 border border-grey rounded p-4"}>
          <Text
            text={"Performance"}
            size={fontSizes.h14}
            color={"text-primary-light"}
            className={"mb-2"}
          />

          <Line data={performanceData} options={performanceOptions} />
        </ViewItems>
        <ViewItems className={"w-1/2 border border-grey rounded p-4"}>
          <Text
            text={"Returns"}
            size={fontSizes.h14}
            color={"text-primary-light"}
            className={"mb-2"}
          />

          <ViewReverse
            className={
              "w-full gap-4 justify-between py-3 px-4 rounded-t bg-grey bg-opacity-50"
            }
          >
            <Text
              text={"Description"}
              size={fontSizes.h14}
              weight={TextWeight.bold}
            />
            <Text
              text={"Returns in %"}
              size={fontSizes.h14}
              weight={TextWeight.bold}
            />
          </ViewReverse>

          <ViewReverse
            className={
              "w-full gap-4 justify-between py-3 px-4 border border-grey border-opacity-50"
            }
          >
            <Text
              text={"Since inception"}
              size={fontSizes.h13}
              weight={TextWeight.light}
            />
            <Text
              text={"59.04"}
              size={fontSizes.h13}
              weight={TextWeight.light}
            />
          </ViewReverse>
          <ViewReverse
            className={
              "w-full gap-4 justify-between py-3 px-4 border border-grey border-opacity-50"
            }
          >
            <Text
              text={"Since inception (annualized)"}
              size={fontSizes.h13}
              weight={TextWeight.light}
            />
            <Text
              text={"7.96"}
              size={fontSizes.h13}
              weight={TextWeight.light}
            />
          </ViewReverse>
          <ViewReverse
            className={
              "w-full gap-4 justify-between py-3 px-4 border border-grey border-opacity-50"
            }
          >
            <Text
              text={"1-Year"}
              size={fontSizes.h13}
              weight={TextWeight.light}
            />
            <Text
              text={"6.08"}
              size={fontSizes.h13}
              weight={TextWeight.light}
            />
          </ViewReverse>
          <ViewReverse
            className={
              "w-full gap-4 justify-between py-3 px-4 border border-grey border-opacity-50"
            }
          >
            <Text
              text={"3-Year (annualized)"}
              size={fontSizes.h13}
              weight={TextWeight.light}
            />
            <Text
              text={"-0.17"}
              size={fontSizes.h13}
              weight={TextWeight.light}
            />
          </ViewReverse>
          <ViewReverse
            className={
              "w-full gap-4 justify-between py-3 px-4 border border-grey border-opacity-50"
            }
          >
            <Text
              text={"6-Year (annualized)"}
              size={fontSizes.h13}
              weight={TextWeight.light}
            />
            <Text
              text={"6.50"}
              size={fontSizes.h13}
              weight={TextWeight.light}
            />
          </ViewReverse>
        </ViewItems>
      </ViewReverse>

      <Text
        text="As of 31 December 2018"
        size={fontSizes.h14}
        className={"mt-6"}
      />
    </div>
  );
};

export default Performance;
