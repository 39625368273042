import React, { useState } from "react";
import Text from "../../../components/Text";
import dynamicfunction from "../../../utils/dynamicfunction";
import { fontSizes } from "../../../constants/constants";
import ViewItems from "../../../components/ViewItems";
import ViewReverse from "../../../components/ViewReverse";
import CustomDropdown from "../../../components/CustomDropdown";
import {
  beneficiaryColumns,
  dropdownOptions,
} from "../../../config/config";
import CustomInput from "../../../components/CustomInput";
import Button from "../../../components/Button";
import { ConfigProvider, Table } from "antd";
import { useSelector } from "react-redux";
import { rowReverseStylesm } from "../../../utils/styles";
import { getContentKeyAccToLang } from "../../../utils/utils";

const Beneficiaries = () => {
  const { beneficiaryData } = useSelector(state => state.transfer);
  const { TextWeight, isArabic } = dynamicfunction();
  const { transferContent } = useSelector((state) => state.content);

  const [beneficiariesData, setBeneficiariesData] = useState({
    type: "",
    bankName: "",
    branch: "",
    currency: "",
  });

  const getContentKey = (key) => {
    return getContentKeyAccToLang(
      transferContent?.web_transfer_beneficiary?.data?.attributes,
      key,
      isArabic,
    );
  };

  const handleBeneficiariesData = (key, val) =>
    setBeneficiariesData((prev) => ({ ...prev, [key]: val }));

  return (
    <div>
      <Text
        text={getContentKey("heading")}
        weight={TextWeight.headingBold}
        size={fontSizes.h24}
      />

      <ViewItems className={"border border-grey mt-2 rounded px-5 py-3"}>
        <Text
          text={getContentKey("add_new")}
          color="text-primary-light"
          size={fontSizes.h14}
          className={"mb-5"}
        />

        <ViewReverse className={`w-full gap-6 flex-col ${rowReverseStylesm(isArabic)}`}>
          <CustomDropdown
            label={getContentKey("type")}
            options={dropdownOptions}
            selectedValue={beneficiariesData.type}
            onSelect={(val) => handleBeneficiariesData("type", val)}
            className={"w-full md:w-1/2"}
          />
          <CustomDropdown
            label={getContentKey("bank_name")}
            options={dropdownOptions}
            selectedValue={beneficiariesData.bankName}
            onSelect={(val) => handleBeneficiariesData("bankName", val)}
            className={"w-full md:w-1/2"}
          />
        </ViewReverse>

        {typeof beneficiariesData.type == "number" && (
          <ViewReverse className={"w-full gap-6 mt-4"}>
            <CustomDropdown
              label={getContentKey("branch")}
              options={dropdownOptions}
              selectedValue={beneficiariesData.branch}
              onSelect={(val) => handleBeneficiariesData("branch", val)}
            />
            <CustomInput
              label={getContentKey("address")}
              placeholder={"Enter address"}
              isFullborder
              isRounded
              type="text"
            />
          </ViewReverse>
        )}

        <ViewReverse className="w-full gap-6 my-4">
          <CustomInput
            label={getContentKey("benficiery_name")}
            placeholder={"Client name as per system"}
            isFullborder
            isRounded
          />
          {typeof beneficiariesData.type == "number" ? (
            <CustomInput
              label={getContentKey("account_number")}
              placeholder={"011231231231231231231230"}
              isFullborder
              isRounded
              type="number"
            />
          ) : (
            <div className="w-full" />
          )}
        </ViewReverse>

        <ViewReverse className={`w-full gap-6 flex-col ${rowReverseStylesm(isArabic)}`}>
          <CustomInput
            label={getContentKey("iban")}
            placeholder={getContentKey("iban_placeholder")}
            isFullborder
            isRounded
            type="number"
          />

          <ViewReverse className={"w-full gap-6"}>
            {typeof beneficiariesData.type != "number" && (
              <CustomDropdown
                label={getContentKey("branch")}
                options={dropdownOptions}
                selectedValue={beneficiariesData.branch}
                onSelect={(val) => handleBeneficiariesData("branch", val)}
                width="w-3/4"
              />
            )}
            <CustomDropdown
              label={getContentKey("currency")}
              options={dropdownOptions}
              selectedValue={beneficiariesData.currency}
              onSelect={(val) => handleBeneficiariesData("currency", val)}
              width="w-1/4"
            />
          </ViewReverse>
        </ViewReverse>

        <ViewReverse className="w-full gap-6 my-4">
          {typeof beneficiariesData.type == "number" && (
            <CustomInput
              label={getContentKey("routing_number")}
              placeholder={getContentKey("routing_number_placeholder")}
              isFullborder
              isRounded
              divClasses={"my-4"}
              width="w-1/2"
            />
          )}
          <CustomInput
            label={getContentKey("swift_code")}
            placeholder={getContentKey("swift_code_placeholder")}
            isFullborder
            isRounded
            divClasses={"my-4"}
            width="w-1/2"
          />
          {typeof beneficiariesData.type != "number" && (
            <div className="w-1/2" />
          )}
        </ViewReverse>

        <ViewReverse className="items-center w-full justify-end gap-2 mt-8 mb-6">
          <Button
            text={getContentKey("submit")}
            bgColor="bg-orange"
            className={"w-32 px-0 text-center border border-orange"}
          />
          <Button
            text={getContentKey("clear")}
            color={"text-dark-grey"}
            bgColor="bg-white"
            className={
              "border border-dark-grey w-32 px-0 text-center border-opacity-50"
            }
          />
        </ViewReverse>
      </ViewItems>

      <ViewItems className={"border border-grey mt-6 rounded px-5 py-3"}>
        <Text
          text={getContentKey("beneficiery_list")}
          color="text-primary-light"
          size={fontSizes.h14}
          className={"mb-3"}
        />

        <ConfigProvider
          theme={{
            components: {
              Table: {
                headerBg: "#E0E0E0",
                headerSplitColor: "transparent",
                borderRadius: 0,
                padding: 15,
              },
            },
          }}
        >
          <Table
            columns={isArabic ? beneficiaryColumns(TextWeight, getContentKey)?.reverse() : beneficiaryColumns(TextWeight, getContentKey)}
            dataSource={beneficiaryData?.beneficiariesList?.length > 0 ? beneficiaryData?.beneficiariesList : null}
            pagination={false}
            className="rounded-tr-[10px] rounded-tl-[10px] border-t-grey border overflow-hidden"
            scroll={{
              x: "max-content",
            }}
            locale={{ emptyText: "No data available in table" }}
            onRow={(i) => ({
              // onClick: (e) => {
              // },
            })}
          />
        </ConfigProvider>
      </ViewItems>
    </div>
  );
};

export default Beneficiaries;
